import { createGlobalStyle } from "styled-components";

const mainFont = "Inter, sans-serif";
const mainFontSize = "14px";
const mainFontWeight = "400";

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    color: ${({ theme }) => theme.neutral.first};
    font-family: ${mainFont};
    font-size: ${mainFontSize};
    font-weight: ${mainFontWeight};
    background: ${({ theme }) => theme.bodyBackgroundColor};
  }

  h1, h2, h3, h4, h5, h6, p, span, select, input {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
  }

  ul, ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  input::-webkit-search-decoration,
  input::-webkit-search-cancel-button,
  input::-webkit-search-results-button,
  input::-webkit-search-results-decoration {
    display: none;
  }

  input {
    border: 1px solid rgba(0, 0, 0, 0.5);
  }

  button {
    padding: 0;
    font: inherit;
    background-color: transparent;
    cursor: pointer;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  a:hover, a:focus, a:active {
    text-decoration: none;
  }


  #root {
    height: 100%;
  }

  .default-link {
    color: ${({ theme }) => theme.neutral.black};
    text-decoration: underline;
  }

  .neutral {
    &-first {
      color: ${({ theme }) => theme.neutral.first};
    }

    &-second {
      color: ${({ theme }) => theme.neutral.second};
    }

    &-third {
      color: ${({ theme }) => theme.neutral.third};
    }

    &-fourth {
      color: ${({ theme }) => theme.neutral.fourth};
    }

    &-fivth {
      color: ${({ theme }) => theme.neutral.five};
    }
  }

  //Custom style by className//

  .ellipses {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
  }

  //React Pro sidebar
  .ps-sidebar-root.filter-aside {
    height: 100%;
    border-radius: 40px 0 0 0;

    .ps-sidebar-container {
      border-radius: 40px 0 0 0;
    }

    .ps-sidebar-backdrop {
      display: none;
    }

    @media screen and (max-width: 480px) {
      border-radius: 0;

      .ps-sidebar-container {
        border-radius: 0;
      }
    }
  }

  .ps-sidebar-root.filter-aside.ps-toggled {
    box-shadow: 14px 4px 55px 0 rgba(20, 17, 62, 0.3);
  }

  .ps-sidebar-root.sidebar-aside {
    //margin-top: -5px;
    height: 100%;
    position: fixed;
    border-color: transparent;
    z-index: 3;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: -44px;
      width: 44px;
      height: 44px;
      border: none;
      border-radius: 100% 0 0 0;
      box-shadow: ${({ theme }) => theme.sidebar.backgroundColor} -44px -44px 0px 44px;
      //z-index: 1;

      @media screen and (max-width: 992px) {
        display: none;
      }
    }

    &::after {
      // content: "";
      // position: absolute;
      // top: 0;
      // left: 100%;
      // width: 100vw;
      // height: 100vh;
        //     //border-top: 5px solid ${({ theme }) => theme.brandColor};
        //     //border-left: 5px solid ${({ theme }) => theme.brandColor};
        //   box-shadow: inset 6px 6px 0 -1px ${({ theme }) => theme.brandColor};
      // border-radius: 37px 0 0 0;

      --border-w: 6px;
      content: "";
      position: absolute;
      top: -1px;
      left: calc(100% - 1px);
      width: 100vw;
      height: 100vh;
      border-radius: 44px;
      padding: var(--border-w);
      background-color: ${({ theme }) => theme.brandColor};
      box-shadow: inset 2px 2px 0 -1px ${({ theme }) => theme.brandColor};
      ${({ theme }) => `background: linear-gradient(135deg, ${theme.brandColor} 25%, ${theme.sidebar.backgroundColor} 50%);`}
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#FFFFFF 0 0);
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#FFFFFF 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;


      @supports (pointer-events: none) {
        pointer-events: none;
      }

      @supports not (pointer-events: none) {
        display: none;
      }
    }

    & > div {
      background-color: ${({ theme }) => theme.sidebar.backgroundColor};

      //&:not(:hover)::-webkit-scrollbar-thumb {
      //  background: transparent;
      //}

      // &::-webkit-scrollbar {
      //   -webkit-appearance: none;
      //   width: 4px;
      //   background-color: transparent;
      //   border-radius: 8px;
      // }
      //
      // &::-webkit-scrollbar-thumb {
      //   width: 5px;
      //   border: 1px solid transparent;
        //   background-color: ${({ theme }) => theme.primary.second};
      //   border-radius: 6px;
      // }

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        display: none;
      }

      &::-webkit-scrollbar-thumb {
        background-color: transparent;
      }
    }

    @media screen and (max-width: 992px) {
      margin-top: 0;

      &::before,
      &::after {
        display: none;
      }

      & > div {
        background-color: rgb(0, 0, 0, 0.3);
      }
    }
  }

  //AntDesign dialog window
  .rc-dialog-mask {
    backdrop-filter: blur(2px);
  }

  .default-modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;

    &.sm {
      .default-modal {
        max-width: 358px;
      }
    }

    .rc-dialog {
      @media screen and (max-width: 768px) {
        margin: 10px auto;
      }

      @media screen and (max-width: 370px) {
        margin: 10px;
      }
    }

    .rc-dialog-close {
      color: ${({ theme }) => theme.filter.iconColor};
      font-size: 14px;
      text-shadow: none;
      top: 14px;
      right: 14px;
      outline: none;
      z-index: 1;
      transition: all .3s ease;
      opacity: 1;

      &-x:after {
        content: "\\e921";
        font-size: 16px;
        font-family: 'extop-main-icons', serif;
      }

      &:hover {
        color: ${({ theme }) => theme.neutral.first};
      }
    }

    .rc-dialog-content {
      background: ${({ theme }) => theme.modal.backgroundColor};
      border: 1px solid ${({ theme }) => theme.modal.borderColor};
      border-radius: 16px;
    }

    .rc-dialog-header {
      padding: 40px 32px 24px;
      background: ${({ theme }) => theme.modal.backgroundColor};
      border: none;
      border-top-left-radius: 17px;
      border-top-right-radius: 17px;

      .rc-dialog-title {
        color: ${({ theme }) => theme.neutral.first};
        font-size: 23px;
        font-weight: 400;
        line-height: 1;
        position: relative;
      }

      @media screen and (max-width: 768px) {
        padding: 40px 24px 12px;
      }
    }

    .rc-dialog.create-payment-modal {
      width: auto;
      margin: 0 10px;

      @media screen and (min-width: 450px) {
        width: 350px;
        margin: 0 auto;
      }
    }

    .rc-dialog-body {
      padding: 24px 32px 32px;

      @media screen and (max-width: 768px) {
        padding: 12px 24px 24px;
      }
    }

    .default-modal {
      &__content {
        max-height: calc(100vh - 375px);
        color: ${({ theme }) => theme.neutral.first};
        overflow-y: auto;

        a {
          color: ${({ theme }) => theme.primary.first};
        }

        h3, h4 {
          padding-top: 4px;
          padding-bottom: 15px;
          color: ${({ theme }) => theme.primary.first};
          font-size: 16px;
          font-weight: 600;
          line-height: 21px;
        }

        p {
          &:not(:last-child) {
            padding-bottom: 15px;
          }
        }

        b {
          padding-bottom: 15px;
          display: inline-block;
        }

        ul {
          padding-bottom: 15px;
          line-height: 15px;
          list-style: disc;
          padding-left: 22px;

          li {
            margin-bottom: 10px;
            position: relative;

            &:before {
              display: none;
              position: absolute;
              left: 0;
            }

            &::marker {
              font-weight: 700;
              color: ${({ theme }) => theme.primary.first};
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      &__footer {
        margin-top: 24px;
        padding-top: 15px;
        display: flex;
        gap: 15px;
        justify-content: end;

        @media screen and (max-width: 768px) {
          padding-top: 0;
        }
      }
    }
  }

  .default-modal-wrapper_confirm {
    .rc-dialog-close {
      display: none;
    }

    .confirm-modal {
      max-width: 400px;
      margin: 10px auto;

      @media screen and (max-width: 576px) {
        max-width: inherit;
        margin: 10px;
      }
    }
  }

  //AntDesign notifications
  .rc-notification {
    padding: 0;
    transform: translateX(-50%);
    z-index: 1055;
    @media screen and (max-width: 576px) {
      width: 100%;
      padding: 0 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: inherit;
      left: 0 !important;
    }

    .rc-notification-notice-close {
      color: black;
      opacity: .5;

      &:hover {
        opacity: 1;
      }
    }

    .rc-notification-notice {
      margin: 0;
      padding: 5px 20px 0 0;
      background-color: transparent;
      border-radius: 0;
      box-shadow: none;
    }

    .message-with-icon {
      min-height: 50px;
      padding: 15px 15px 15px 55px;
      color: ${({ theme }) => theme.text};
      display: inline-flex;
      border: 1px solid transparent;
      border-radius: 12px;
      position: relative;

      &:before {
        width: 25px;
        height: 25px;
        font-size: 22px;
        font-family: 'default-icons', serif;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        position: absolute;
        top: 14px;
        left: 15px;
      }

      .default-link {
        cursor: pointer;
      }
    }

    .message-with-icon.success {
      ${({ theme }) => `
        background-color: ${theme.success.third};
        border-color: ${theme.success.first};
        &:before {
          content: '\\e92b';
          background-color: ${theme.success.first};
          color: ${theme.neutral.white};
        }
      `}
    }

    .message-with-icon.error {
      ${({ theme }) => `
        background-color: ${theme.danger.third};
        border-color: ${theme.danger.first};
        &:before {
          content: '\\e92a';
          background-color: ${theme.danger.first};
          color: ${theme.neutral.white};
        }
      `}
    }

    .message-with-icon.info {
      ${({ theme }) => `
        background-color: ${theme.info.third};
        border-color: ${theme.info.first};
        &:before {
          content: '\\e92a';
          background-color: ${theme.info.first};
          color: ${theme.neutral.white};
        }
      `}
    }

    .message-with-icon.warning {
      ${({ theme }) => `
        background-color: ${theme.warning.third};
        border-color: ${theme.warning.first};
        &:before {
          content: '\\e92a';
          background-color: ${theme.warning.first};
          color: ${theme.neutral.white};
        }
      `}
    }
  }

  //AntDesign custom tabs
  .rc-tabs-dropdown {
    padding: 5px;
    color: ${({ theme }) => theme.neutral.first};
    background-color: ${({ theme }) => theme.neutral.white};
    border: 1px solid ${({ theme }) => theme.neutral.five};
    border-radius: 12px;
    z-index: 10;

    .rc-tabs-dropdown-menu-item {
      cursor: pointer;

      &:hover {
        background: ${({ theme }) => theme.customSelect.dropdown.itemBackgroundColorHover};
        border-radius: 8px;
      }
    }

    @media screen and (max-width: 576px) {
      display: none;
    }
  }

  .default-tabs {
    border: none;
    grid-gap: 24px;

    .rc-tabs-nav-more {
      margin-left: 5px;
      color: ${({ theme }) => theme.neutral.second};
      font-size: 16px;
      font-weight: 700;
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.neutral.six};
      border-radius: 12px;
      outline: none;
    }

    .rc-tabs-nav-list {
      .rc-tabs-tab {
        font-size: 14px;
        color: ${({ theme }) => theme.neutral.second};
        font-weight: 400;
        transition: all .3s linear;

        &:hover {
          color: ${({ theme }) => theme.primary.first};
        }

        .rc-tabs-tab-btn {
          line-height: 20px;
          outline: none;
        }
      }

      .rc-tabs-tab-active {
        opacity: 1;
        color: ${({ theme }) => theme.primary.first};
        font-weight: 500;
      }

      .rc-tabs-tab-disabled {
        opacity: 0.7;
        cursor: not-allowed;

        &:hover {
          color: ${({ theme }) => theme.neutral.second};
        }
      }

      .rc-tabs-ink-bar {
        display: none;
      }

      .rc-tabs-tab {
        position: relative;
        border: 0;

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 0;
          height: 2px;
          background-color: ${({ theme }) => theme.tabs.activeTextColor};
          border-radius: 20px;
          transition: width 0.3s ease;
        }

        &.rc-tabs-tab-active::after {
          width: 100%;
        }
      }

      @media screen and (max-width: 576px) {
        display: grid;
        grid-template-rows: auto;
        .rc-tabs-tab {
          margin: 0px;
          padding: 5px 15px;
        }

        .rc-tabs-ink-bar {
          display: none;
        }

        .rc-tabs-tab-active {
          background-color: rgba(160, 150, 130, 0.15);
          border-radius: 5px;
        }
      }
    }

    .rc-tabs-content-holder {
      .rc-tabs-tabpane {
        outline: none;
      }
    }

    @media screen and (max-width: 992px) {
      grid-template-columns: 100%;
      grid-template-rows: repeat(2, auto);
    }
  }

  .default-tabs-left {
    display: grid;
    grid-template-columns: 256px auto;

    .rc-tabs-tab {
      margin: 0px;
      padding: 5px 15px;
    }

    .rc-tabs-ink-bar {
      display: none;
    }

    .rc-tabs-tab-active {
      color: ${({ theme }) => theme.neutral.white};
      font-weight: 700;
      background-color: rgba(160, 150, 130, 0.15);
      border: 1px solid ${({ theme }) => theme.neutral.white};
      border-radius: 5px;
    }

    @media screen and (max-width: 992px) {
      grid-template-columns: 100%;
      grid-template-rows: auto;
    }
  }

  .default-tabs-top {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 36px auto;
    overflow: initial;

    .rc-tabs-nav-list {
      width: 100%;
      border-bottom: 1px solid ${({ theme }) => theme.tabs.borderColor};
    }

    .rc-tabs-tab {
      margin-right: 20px;
      padding: 0 0 10px;
      background: transparent;
      color: ${({ theme }) => theme.tabs.notActiveTextColor} !important;

      &:last-child {
        margin: 0;
      }
    }

    .rc-tabs-tab-active {
      color: ${({ theme }) => theme.tabs.activeTextColor};
      font-weight: 500;

      span {
        color: ${({ theme }) => theme.tabs.iconActiveColor}
      }
    }

    @media screen and (max-width: 576px) {
      grid-template-columns: 100%;
      grid-template-rows: minmax(40px, auto) auto;
    }
  }

  //AntDesign select
  .rc-select-item-empty {
    padding: 8px;
    font-size: 14px;
  }

  .rc-select-selector {
    min-height: 42px;
    display: flex;
    align-items: stretch;

    .rc-select-selection-overflow {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      &-item {
        display: inline-flex;
      }
    }

    span.rc-select-selection-item, span.rc-select-selection-placeholder {
      width: 100%;
      padding: 7px 16px;
      display: flex;
    }
  }

  .rc-select-dropdown {
    padding: 8px;
    box-shadow: none;
    border-radius: 12px;
    border: 1px solid ${({ theme }) => theme.customSelect.dropdown.borderColor};
    z-index: 1100;

    .rc-select-item-option {
      cursor: pointer;
      padding: 4px 0;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }

      .option-select-item {
        padding: 12px 8px;
        color: ${({ theme }) => theme.customSelect.dropdown.textColor};
        font-size: 14px;
        display: flex;
        align-items: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        border-radius: 10px;

        span {
          padding-right: 20px;
          font-size: 16px;
        }
      }

      &:hover {
        .option-select-item {
          background: ${({ theme }) => theme.customSelect.dropdown.itemBackgroundColorHover};
        }
      }
    }

    .rc-select-item-option-selected {
      .option-select-item {
        color: ${({ theme }) => theme.primary.first};
        background: ${({ theme }) => theme.customSelect.dropdown.selectedBackgroundColor} !important;
        border-radius: 8px;
      }

      .rc-select-item-option-state {
        display: none;
      }
    }
  }

  //AntDesign custom checkbox
  .default-checkbox {
    width: 20px;
    height: 20px;

    input {
      width: 20px;
      height: 20px;

      &:focus {
        box-shadow: none;
        outline: none;
      }

      &:focus + .rc-checkbox-inner {
        border-color: ${({ theme }) => theme.neutral.seven};
      }
    }

    .rc-checkbox-inner {
      width: 20px;
      height: 20px;
      border-radius: 8px;
      border: 1px solid ${({ theme }) => theme.neutral.seven};
      background-color: #fff;

      &:after {
        left: 5px;
        top: 2px;
        border: none;
        border-collapse: separate;
      }
    }
  }

  .rc-checkbox-checked {
    .rc-checkbox-inner {
      background-color: ${({ theme }) => theme.primary.first};
      border-color: ${({ theme }) => theme.primary.first};

      &:after {
        border: 2px solid #fff;
        border-top: 0;
        border-left: 0;
      }
    }
  }

  //AntDesign drawer custom style
  .drawer {
    z-index: 998;
  }

  .drawer.drawer-open .drawer-mask {
    opacity: 0.45;
  }

  .drawer-left .drawer-content {
    background-color: ${({ theme }) => theme.sidebar.backgroundColor};
  }

  .drawer-right .drawer-content {
    background-color: ${({ theme }) => theme.sidebar.backgroundColor};
  }

  //AntDesign switch
  .default-switch {
    width: 36px;
    height: 20px;
    background-color: ${({ theme }) => theme.switch.backgroundColor};
    border-color: ${({ theme }) => theme.switch.borderColor};

    &:after {
      width: 16px;
      height: 16px;
      background-color: ${({ theme }) => theme.neutral.white};
      box-shadow: none;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .rc-switch-checked {
    background-color: ${({ theme }) => theme.primary.first};
    border-color: ${({ theme }) => theme.primary.first};

    &:after {
      left: 16px;
    }
  }

  .rc-switch-disabled {
    opacity: 0.45;
  }

  //AntDesign DatePicker
  .react-date-picker {
    &__inputGroup {
      padding: 0 35px !important;
    }
  }

  //Two fa input
  .twofa-input {
    input {
      text-align: center;
      font-weight: 600;
      letter-spacing: 5px;
      //padding: 18px 16px;
    }

    //button.insert-button {
    //  right: 16px;
    //  padding: 6px 12px;
    //}
  }

  //Two fa lib inputs 
  .twofa-container {
    display: flex;
    justify-content: center;
    gap: 11px;

    &__input {
      width: 49px;
      height: 49px;
      font-size: 28px;
      font-weight: 600;
      font-family: ${mainFont};
      text-align: center;
      background-color: ${({ theme }) => theme.input.backgroundColor};
      border: 1px solid ${({ theme }) => theme.input.borderColor};
      border-radius: 13px;
      -webkit-appearance: none;
      transition: all 0.3s ease;

      &:focus {
        border-color: ${({ theme }) => theme.primary.first};
      }

      &_error {
        border-color: ${({ theme }) => theme.danger.first};
      }
    }

    @media screen and (max-width: 576px) {
      gap: 7px;

      &__input {
        width: 42px;
        height: 42px;
        font-size: 22px;
      }
    }

    @media screen and (max-width: 375px) {
      &__input {
        width: 36px;
        height: 36px;
        font-size: 20px;
      }
    }
  }

  //Filter mobile dialog window
  .filter-modal-wrapper {
    height: 100%;

    .filter-modal {
      top: 0;
      left: 0;
      margin: 0;
      width: 100%;
      height: 100%;
    }

    .rc-dialog-close {
      top: 35px;
      right: 15px;
      z-index: 1;
      transition: all .3s ease;

      &-x:after {
        content: "\\e907";
        font-size: 22px;
        font-family: 'default-icons', serif;
      }
    }

    .rc-dialog-content {
      min-height: 100%;
      display: flex;
      flex-direction: column;
    }

    .rc-dialog-header {
      padding: 40px 15px 15px;
      border: none;

      .rc-dialog-title {
        color: ${({ theme }) => theme.neutral.black};
        font-size: 16px;
        font-weight: 600;
      }
    }

    .rc-dialog-body {
      flex: 1;
      padding: 15px;
      display: flex;
    }

    .default-modal {
      &__content {
        flex: 1;
        overflow-y: auto;

        p {
          &:not(:last-child) {
            padding-bottom: 15px;
          }
        }
      }
    }
  }
`;
