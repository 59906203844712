import { StyledWidgetPageSkeletonWrapper } from "./styledWidgetPageSkeleton";
import { StyledContainer } from "../../../styles/styledContainer";
import CalculatorLoader from "../../../elements/calculatorLoader/CalculatorLoader";

const WidgetPageSkeleton = () => {
  return (
    <StyledContainer>
      <StyledWidgetPageSkeletonWrapper>
        <CalculatorLoader />
      </StyledWidgetPageSkeletonWrapper>
    </StyledContainer>
  );
};

export default WidgetPageSkeleton;