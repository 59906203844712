import styled from "styled-components";

import apple from "../../assets/images/icon-apple.svg";
import google from "../../assets/images/icon-google.svg";
import { StyledLabel } from "../elements/inputGroup/styledInputGroup";
import { StyledButton } from "../styles/styledButton";
import { StyledCheckboxLabel, StyledCheckboxWrapper } from "../styles/styledCheckbox";
import { StyledMainTitle } from "../styles/styledTitle";
import { StyledParagraph, StyledTextRegular } from "../styles/styledTypography";

export const StyledAuthWrapper = styled.div`
  --headerHeight: 65px;
  --containerPaddingBottom: 32px;
  width: 100%;
  height: calc(100% - var(--headerHeight) + var(--containerPaddingBottom));
  display: flex;
  justify-content: center;
  align-items: center;

  ${StyledMainTitle} {
    line-height: 1;
  }

  ${StyledParagraph} {
    color: ${({ theme }) => theme.neutral.six};

    a {
      color: ${({ theme }) => theme.primary.first};
      font-weight: 500;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .sub-text {
    padding-top: 24px;
    text-align: center;
  }

  @media screen and (max-width: 992px) {
    height: 100%;
    padding-top: 28px;
  }

  @media screen and (max-width: 768px) {
    ${StyledMainTitle} {
      margin-bottom: 24px;
      font-size: 24px;
    }
  }
`;

export const StyledAuthFormWrapper = styled.div`
  max-width: 360px;
  width: 100%;
  min-height: 360px;

  ${StyledLabel} {
    line-height: 16px;
  }
`;

export const StyledAuthAgreeCheckbox = styled.div`
  margin-bottom: 16px;

  ${StyledParagraph} {
    font-size: 12px;
  }

  ${StyledCheckboxWrapper} {
    align-items: flex-start;
  }

  ${StyledCheckboxLabel} {
    font-size: 12px;

    a {
      color: ${({ theme }) => theme.primary.first};
      font-weight: 500;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const StyledAuthActions = styled.div``;

export const StyledAuthWithSocialWrapper = styled.div`
  max-width: 420px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 16px;

  .social-login {
    padding-left: 26px;
    position: relative;

    &::before {
      content: "";
      height: 18px;
      width: 18px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    &_with-google::before {
      background-image: url(${google});
    }

    &_with-apple::before {
      background-image: url(${apple});
    }
  }

  ${StyledButton} {
    width: 100%;
    color: ${({ theme }) => theme.neutral.first};
    border-color: ${({ theme }) => theme.neutral.twelfth};

    &:hover {
      background-color: ${({ theme }) => theme.neutral.twelfth};
    }
  }
`;

export const StyledAuthTextSeparator = styled(StyledTextRegular)`
  padding: 24px 0;
  text-align: center;
  color: ${({ theme }) => theme.neutral.fourth};
  position: relative;
  display: flex;
  text-wrap: nowrap;

  &::before,
  &::after {
    content: "";
    width: 40%;
    height: 1px;
    margin-top: 13px;
    background-color: ${({ theme }) => theme.neutral.twelfth};
  }

  &::before {
    margin-right: 16px;
  }

  &::after {
    margin-left: 16px;
  }
`;

export const StyledAuthBackButton = styled.div`
  padding-top: 50px;

  button {
    padding-left: 23px;
    color: ${({ theme }) => theme.primary.first};
    font-size: 14px;
    display: inline-flex;
    justify-content: center;
    gap: 8px;
    outline: none;
    border: none;
    position: relative;

    &::before {
      content: "\\e901";
      width: 15px;
      height: 15px;
      font-family: "extop-main-icons", sans-serif;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
`;
