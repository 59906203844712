import { StyledSidebarItem, StyledSidebarSubLinkList, StyledSidebarSubLinkWrapper } from "./styledSidebar";
import { StyledSpan, StyledTextRegular } from "../../styles/styledTypography";
import { Trans } from "react-i18next";
import withPermissionCheckComponent from "../../../utils/customHOC/withPermissionCheckComponent";
import SidebarItemSubLink from "./SidebarItemSubLink";
import { Menu, SubMenu } from "react-pro-sidebar";

const SidebarSubMenu = ({ t, title, icon, subLinks, match, sidebarCollapsed }) => {
  return (
    <StyledSidebarSubLinkWrapper as={Menu} collapsed={sidebarCollapsed}>
      <StyledSidebarSubLinkList
        collapsed={sidebarCollapsed}
        as={SubMenu}
        label={
          <StyledSidebarItem
            className="submenu__title"
            collapsed={sidebarCollapsed}
          >
            <StyledSpan fontSize="20" className={icon} />
            <StyledTextRegular>
              <Trans>{t(title)}</Trans>
            </StyledTextRegular>
          </StyledSidebarItem>
        }
      >
        {subLinks.map((subLink) => {
          const SidebarItemSubLinkWithPerm = withPermissionCheckComponent(SidebarItemSubLink, subLink?.permission);

          return (
            <SidebarItemSubLinkWithPerm
              key={subLink.path}
              path={subLink.path}
              title={t(`${subLink.title}`)}
              icon={subLink.icon}
              match={match}
            />
          );
        })}
      </StyledSidebarSubLinkList>
    </StyledSidebarSubLinkWrapper>
  );
};

export default SidebarSubMenu;