import { createSlice } from "@reduxjs/toolkit";

import {
  fetchExchangerUserBillingAddressReducer,
  updateUserBillingAddressReducer,
  validateUserBillingAddressReducer,
} from "./billingAddressReducer";

const initialState = {
  billingAddress: [],
  errors: null,
  loading: true,
  updateBillingAddressLoading: false,
};

const billingAddressSlice = createSlice({
  name: "billingAddress",
  initialState,
  reducers: {
    clearStateBillingAddress: (state) => {
      state.billingAddress = [];
      state.errors = null;
      state.loading = true;
    },
    setBillingAddress: (state, action) => {
      state.billingAddress = action.payload;
    },
  },
  extraReducers: (builder) => {
    fetchExchangerUserBillingAddressReducer(builder);
    updateUserBillingAddressReducer(builder);
    validateUserBillingAddressReducer(builder);
  },
});

export const { clearStateBillingAddress, setBillingAddress } = billingAddressSlice.actions;

export default billingAddressSlice.reducer;
