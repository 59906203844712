import { errorsParser } from "../../utils/errorsParser";
import {
  createExchangerUserVerification,
  fetchExchangerUserVerification,
  fetchExchangerUserVerifiedVerificationSchemas,
} from "./asyncDocuments";

const fetchExchangerUserVerificationReducer = (builder) => {
  builder.addCase(fetchExchangerUserVerification.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(fetchExchangerUserVerification.fulfilled, (state, action) => {
    state.link = action.payload.data[0].link;
    state.status = action.payload.data[0].status;
    state.schema = action.payload.data[0].schema;
    state.loading = false;
  });
  builder.addCase(fetchExchangerUserVerification.rejected, (state, action) => {
    errorsParser(action.payload);
    state.loading = false;
  });
};

const createExchangerUserVerificationReducer = (builder) => {
  builder.addCase(createExchangerUserVerification.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(createExchangerUserVerification.fulfilled, (state, action) => {
    state.link = action.payload.data.link;
    state.status = action.payload.data.status;
    state.loading = false;
  });
  builder.addCase(createExchangerUserVerification.rejected, (state, action) => {
    errorsParser(action.payload);
    state.loading = false;
  });
};

const fetchExchangerUserVerifiedVerificationSchemasReducer = (builder) => {
  builder.addCase(fetchExchangerUserVerifiedVerificationSchemas.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(fetchExchangerUserVerifiedVerificationSchemas.fulfilled, (state, action) => {
    state.verificationSchemas = action.payload.data;
    state.loading = false;
  });
  builder.addCase(fetchExchangerUserVerifiedVerificationSchemas.rejected, (state, action) => {
    errorsParser(action.payload);
    state.loading = false;
  });
};

export {
  createExchangerUserVerificationReducer,
  fetchExchangerUserVerificationReducer,
  fetchExchangerUserVerifiedVerificationSchemasReducer,
};
