import { errorsParser } from "../../utils/errorsParser";
import { fetchExchangerCountries } from "./asyncCountries";

const fetchExchangerCountriesReducer = (builder) => {
  builder.addCase(fetchExchangerCountries.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(fetchExchangerCountries.fulfilled, (state, action) => {
    state.countries = action.payload.data;
    state.loading = false;
  });
  builder.addCase(fetchExchangerCountries.rejected, (state, action) => {
    errorsParser(action.payload);
    state.loading = false;
  });
};

export { fetchExchangerCountriesReducer };
