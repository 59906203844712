import { Form, Formik } from "formik";
import { Trans } from "react-i18next";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import { getAuthToken } from "../../../../store/auth/asyncAuthSlice";
import CustomLink from "../../../elements/customLink/CustomLink";
import FormCheckbox from "../../../elements/formComponents/Checkbox";
import TextInput from "../../../elements/formComponents/TextInput";
import LoadButton from "../../../elements/spinner/ButtonSpinner";
import LoginGoogleForm from "../../login/LoginGoogleForm";
import { StyledButton } from "../../../styles/styledButton";
import { StyledMainTitle } from "../../../styles/styledTitle";
import { StyledAuthActions, StyledAuthAgreeCheckbox, StyledAuthTextSeparator } from "../../styledAuth";
import { StyledParagraph } from "../../../styles/styledTypography";

const getSignupSchema = (t) =>
  Yup.object().shape({
    email: Yup.string().required(t("emailStep.errors.requiredError")).email(t("emailStep.errors.invalidEmail")),
    // agree: Yup.boolean().oneOf([true], t("emailStep.errors.agreeError")).required(t("emailStep.errors.required")),
  });

const ExchangerEmailStep = ({ t, authData, setAuthData, loading }) => {
  const dispatch = useDispatch();

  const handleSubmit = async (values, { setFieldError }) => {
    const data = {
      email: values.email,
    };

    try {
      await dispatch(getAuthToken(data)).unwrap();
    } catch (error) {
      if (error) {
        error.forEach((errItem) => {
          if (errItem.email) {
            setFieldError("email", errItem.email);
          }
        });
      }
    }
  };

  return (
    <Formik
      initialValues={{
        email: "",
        // agree: false,
      }}
      validateOnChange={true}
      validateOnBlur={true}
      validationSchema={getSignupSchema(t)}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <>
          <StyledMainTitle mb="34" size="36">
            {t("emailStep.title")}
          </StyledMainTitle>
          <Form>
            <TextInput
              type="text"
              name="email"
              autocomplete="email"
              label={t("emailStep.label")}
              placeholder={t("emailStep.placeholder")}
              icon="input-email"
            />
            <StyledAuthAgreeCheckbox>
              <StyledParagraph>
                <Trans
                  components={{
                    TermsLink: <CustomLink className="default-link" to="/login" />,
                    PrivacyLink: <CustomLink className="default-link" to="/login" />,
                  }}
                  t={t}
                  i18nKey="emailStep.agreeTitle"
                />
              </StyledParagraph>
            </StyledAuthAgreeCheckbox>
            {/*<FormCheckbox*/}
            {/*  name="agree"*/}
            {/*  label={*/}
            {/*    <Trans*/}
            {/*      components={{*/}
            {/*        TermsLink: <CustomLink className="default-link" to="/login" />,*/}
            {/*        PrivacyLink: <CustomLink className="default-link" to="/login" />,*/}
            {/*      }}*/}
            {/*      t={t}*/}
            {/*      i18nKey="emailStep.agreeTitle"*/}
            {/*    />*/}
            {/*  }*/}
            {/*/>*/}
            <StyledAuthActions>
              {isSubmitting || loading ? (
                <LoadButton color="main" width="100%" text={t("emailStep.submit")} />
              ) : (
                <StyledButton color="main" width="100%" type="submit">
                  {t("emailStep.submit")}
                </StyledButton>
              )}
            </StyledAuthActions>
            <StyledAuthTextSeparator>{t("emailStep.orContinueWith")}</StyledAuthTextSeparator>
            <LoginGoogleForm authData={authData} setAuthData={setAuthData} t={t} />
          </Form>
        </>
      )}
    </Formik>
  );
};

export default ExchangerEmailStep;
