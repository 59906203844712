export const permissionUserEndpoints = {
  PUT_EXCHANGER_CHANGE_AVATAR: {
    name: "put_exchanger_change_avatar",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  PUT_EXCHANGER_REMOVE_AVATAR: {
    name: "put_exchanger_remove_avatar",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_ORDERS_GET_COLLECTION: {
    name: "api_exchanger_orders_get_collection",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_ORDERS_GET_ITEM: {
    name: "api_exchanger_orders_get_item",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_USER_INFOS_GET_ITEM: {
    name: "api_exchanger_user_infos_get_item",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_WIDGET_PAIRS_GET_COLLECTION: {
    name: "api_exchanger_widget_pairs_get_collection",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_WIDGET_PAIRS_GET_ITEM: {
    name: "api_exchanger_widget_pairs_get_item",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_WIDGET_PAIRS_PUT_ITEM: {
    name: "api_exchanger_widget_pairs_put_item",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_WIDGETS_GET_COLLECTION: {
    name: "api_exchanger_widgets_get_collection",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_WIDGETS_GET_ITEM: {
    name: "api_exchanger_widgets_get_item",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_WIDGET_POST_COLLECTION: {
    name: "api_exchanger_widgets_post_collection",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  POST_EXCHANGER_WIDGET_SETTINGS: {
    name: "post_exchanger_widget_settings",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_WIDGET_SETTINGS_GET_COLLECTION: {
    name: "api_exchanger_widget_settings_get_collection",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_WIDGET_SETTINGS_GET_ITEM: {
    name: "api_exchanger_widget_settings_get_item",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  EXCHANGER_CREATE_ORDER: {
    name: "exchanger_create_order",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  EXCHANGER_UPDATE_ORDER: {
    name: "exchanger_update_order",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  WIDGET_CREATE_ORDER: {
    name: "widget_create_order",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  GET_CHECK_IF_HAS_FINISHED_ORDERS: {
    name: "get_check_if_has_finished_orders",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  EXCHANGER_CREATE_PAYMENT_INVOICE: {
    name: "exchanger_create_payment_invoice",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  EXCHANGER_PAYMENT_SYSTEM: {
    name: "exchanger_payment_system",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  EXCHANGER_CURRENCY: {
    name: "exchanger_currency",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  EXCHANGER_SERVICE: {
    name: "exchanger_service",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  GET_EXCHANGER_AUTHENTICATOR_SECRET: {
    name: "get_exchanger_authenticator_secret",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  GET_EXCHANGER_AUTHENTICATOR_SECRET_IS_ACTIVE: {
    name: "get_exchanger_authenticator_secret_is_active",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  PUT_EXCHANGER_AUTHENTICATOR_SECRET_REMOVE: {
    name: "put_exchanger_authenticator_secret_remove",
    hasFullAccess: false,
    isTwoFaRequired: true,
  },
  POST_EXCHANGER_AUTHENTICATOR_SECRET_ADD: {
    name: "post_exchanger_authenticator_secret_add",
    hasFullAccess: false,
    isTwoFaRequired: true,
  },
  EXCHANGER_REFRESH_TOKEN: {
    name: "exchanger_refresh_token",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  GET_EXCHANGER_RATE_SERVICES: {
    name: "get_exchanger_rate_services",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  GET_EXCHANGER_REFERRAL_TOKEN: {
    name: "get_exchanger_referral_token",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  GET_EXCHANGER_REFERRAL_USER_LIST: {
    name: "get_exchanger_referral_user_list",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  GET_EXCHANGER_USER_REQUISITES: {
    name: "get_exchanger_user_requisites",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  POST_EXCHANGER_USER_REQUISITES: {
    name: "post_exchanger_user_requisites",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  PATCH_EXCHANGER_USER_REQUISITES: {
    name: "patch_exchanger_user_requisites",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  DELETE_EXCHANGER_USER_REQUISITES: {
    name: "delete_exchanger_user_requisites",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  GET_EXCHANGER_PAIR_UNITS_FOR_REQUISITES: {
    name: "get_exchanger_pair_units_for_requisites",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  GET_EXCHANGER_PAIR_UNITS_FOR_WITHDRAWAL_REQUISITION: {
    name: "get_exchanger_pair_units_for_withdrawal_requisition",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  GET_EXCHANGER_USER_VERIFICATIONS: {
    name: "get_exchanger_user_verifications",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  POST_EXCHANGER_USER_VERIFICATION: {
    name: "post_exchanger_user_verification",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  CHECK_USER_VERIFICATION: {
    name: "check_user_verification",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_BALANCES: {
    name: "get_exchanger_balances",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  GET_EXCHANGER_WIDGET_REPORTS: {
    name: "get_exchanger_widget_reports",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_WITHDRAWAL_REQUISITIONS_POST_COLLECTION: {
    name: "api_exchanger_withdrawal_requisitions_post_collection",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_WITHDRAWAL_REQUISITION_GET_COLLECTION: {
    name: "api_exchanger_withdrawal_requisitions_get_collection",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_WITHDRAWAL_REQUISITIONS_GET_ITEM: {
    name: "api_exchanger_withdrawal_requisitions_get_item",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  UPDATE_EXCHANGER_WIDGET_PAIRS: {
    name: "update_exchanger_widget_pairs",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_USERS_GET_PERSONAL_DATA_ITEM: {
    name: "api_exchanger_users_get_personal_data_item",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  GET_EXCHANGER_USER_BILLING_ADDRESS: {
    name: "get_exchanger_user_billing_address",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  GET_EXCHANGER_WIDGET_ORDERS: {
    name: "get_exchanger_widget_orders",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  GET_EXCHANGER_MAILING_LANGUAGE: {
    name: "get_exchanger_mailing_language",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  UPDATE_EXCHANGER_MAILING_LANGUAGE: {
    name: "update_exchanger_mailing_language",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
};
