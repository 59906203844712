import styled from "styled-components";

export const StyledPaymentMethodWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media screen and (max-width: 992px) {
    ${({ label }) => label && "width: 100%; justify-content: space-between;"}
    flex-direction: row;
    line-height: 16px;
  }
`;

export const StyledPaymentMethodLabel = styled.p`
  margin-bottom: 14px;
  color: ${({ theme }) => theme.neutral.fourth};
  font-size: 12px;
  font-weight: 500;

  @media screen and (max-width: 992px) {
    margin-bottom: 0;
  }
`;

export const StyledPaymentMethodIcon = styled.div`
  width: 20px;
  height: 20px;
  min-width: 20px;

  img {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    object-fit: contain;
    object-position: center;
  }
`;

export const StyledPaymentMethod = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const StyledPaymentMethodTextContent = styled.div`
  ${({ textWrap }) =>
    textWrap &&
    `
    max-width: 120px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `};
`;

export const StyledPaymentMethodService = styled.div`
  padding-left: 26px;
  color: ${({ theme }) => theme.neutral.third};
  font-size: 12px;

  @media screen and (max-width: 992px) {
    padding-left: 5px;
    font-size: 14px;
  }
`;

export const StyledPaymentMethodDirection = styled.div`
  ${({ isDirectionOutside }) => isDirectionOutside && "padding-left: 22px;"};
  color: ${({ theme }) => theme.neutral.third};
  font-size: 12px;

  @media screen and (max-width: 992px) {
    ${({ isDirectionOutside }) =>
      isDirectionOutside &&
      `
      width: 100%; 
      padding-left: 0; 
      text-align: right;
    `};
    font-size: 14px;
  }
`;
