import React, { useEffect, useMemo } from "react";
import { Sidebar } from "react-pro-sidebar";
import { useDispatch, useSelector } from "react-redux";

import { handleCloseSideBar, handleSidebarToggled } from "../../../store/theme/themeSlice";
import { exchangerRoles } from "../../../utils/consts";
import { useMedia } from "../../../utils/customHooks/useMedia";
import { StyledSpan } from "../../styles/styledTypography";
import BalanceButton from "../../userBalance/BalanceButton";
import UserInfo from "../../userInfo/UserInfo";
import SidebarLinkList from "./SidebarLinkList";
import {
  StyledSidebar, StyledSidebarAccount,
  StyledSidebarItem,
  StyledSidebarLink,
  StyledSidebarSiteLinks,
} from "./styledSidebar";
import { getProfitsInfo } from "../../../store/userBalance/asyncUserBalance";
import { clearStateUserBalance } from "../../../store/userBalance/userBalanceSlice";
import { checkPermissionByName } from "../../../utils/customFunc/checkPermission";
import { groupPermissionEndpoints } from "../../../utils/groupEndpoints/groupPermissionEndpoints";

const SidebarContainer = ({ authenticated }) => {
  const dispatch = useDispatch();

  const { email, roles } = useSelector((store) => store.user.user) || {};
  const { sidebarCollapsed, sidebarToggled } = useSelector((store) => store.theme);

  const isExchanger = process.env.REACT_APP_BASE_URL !== window.location.origin;
  const isUserExchangerAdmin = roles?.some((role) => role === exchangerRoles.ADMIN);
  const isUserExchangerClient = roles?.some((role) => role === exchangerRoles.USER);

  const isMobile = useMedia("(max-width: 992px)");
  const extraSmall = useMedia("(max-width: 374px)");

  const haveAccess = useMemo(
    () => checkPermissionByName([groupPermissionEndpoints.API_EXCHANGER_BALANCES]),
    [authenticated]
  );

  useEffect(() => {
    if (isUserExchangerClient && haveAccess && authenticated) {
      dispatch(getProfitsInfo());

      return () => dispatch(clearStateUserBalance());
    }
  }, [authenticated]);

  return (
    <Sidebar
      width={isMobile && !extraSmall ? "325px" : "258px"}
      collapsedWidth="69px"
      className="sidebar-aside"
      breakPoint="lg"
      collapsed={sidebarCollapsed}
      toggled={sidebarToggled}
      onBackdropClick={() => dispatch(handleSidebarToggled())}
    >
      <StyledSidebar>
        <StyledSidebarSiteLinks>
          <StyledSidebarAccount>
            <BalanceButton roles={roles} authenticated={authenticated} setToggled />
            <UserInfo email={email} isMobile={isMobile} />
          </StyledSidebarAccount>
          <SidebarLinkList
            isUserExchangerAdmin={isUserExchangerAdmin}
            isExchanger={isExchanger}
          />
        </StyledSidebarSiteLinks>
        <StyledSidebarLink className="sidebar-collapsed">
          <StyledSidebarItem className="sidebar-collapsed__item">
            <StyledSpan
              onClick={() => dispatch(handleCloseSideBar())}
              fontSize="20"
              className={`sidebar-collapsed__btn ${sidebarCollapsed ? "icon-ex-menu-hide-right" : "icon-ex-menu-hide-left"}`}
            />
          </StyledSidebarItem>
        </StyledSidebarLink>
      </StyledSidebar>
    </Sidebar>
  );
};

export default SidebarContainer;
