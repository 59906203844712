import basicAuthenticationConfig from "../../../utils/basicAuthenticationConfig";
import { regex } from "../../../utils/consts";
import CustomLink from "../../elements/customLink/CustomLink";
import InputGroup from "../../elements/inputGroup/InputGroup";
import { closableNotification } from "../../elements/notification/ClosableNotification";
import { StyledButton } from "../../styles/styledButton";
import { StyledParagraph } from "../../styles/styledTypography";
import { StyledAuthActions } from "../styledAuth";
import LoadButton from "../../elements/spinner/ButtonSpinner";

const LoginForm = ({ loading, authData, setAuthData, t }) => {
  const handleSubmit = (event) => {
    event.preventDefault();

    // if (!event.target.password.value.match(regex.PASSWORD)) {
    //   return closableNotification('Incorrect password format', "error");
    // }

    if (regex.CYRILLIC_SYMBOLS.test(event.target.password.value)) {
      return closableNotification(t("login.alert.passwordContainsInvalidSymbols"), "error");
    }

    let data = {
      email: event.target.email.value,
      password: event.target.password.value,
    };

    setAuthData({
      ...authData,
      headers: basicAuthenticationConfig(data.email, data.password),
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <InputGroup
        id="email"
        type="email"
        label="E-mail"
        placeholder={t("login.emailPlaceholder")}
        name="email"
        maxLength={150}
        required
        icon="input-email"
      />
      <InputGroup
        id="password"
        type="password"
        label={t("login.password")}
        placeholder={t("login.passwordPlaceholder")}
        name="password"
        required
      />
      <StyledParagraph className="forgot-password">
        <CustomLink to="/forgot-password" className="default-link">
          {t("login.forgotPassword")}
        </CustomLink>
      </StyledParagraph>
      <StyledAuthActions>
        {loading ?
          <LoadButton color="main" width="100%" text={t("login.submit")} /> :
          <StyledButton color="main" width="100%" type="submit">
            {t("login.submit")}
          </StyledButton>
        }
      </StyledAuthActions>
    </form>
  );
};

export default LoginForm;
