import styled from "styled-components";

const size = {
  size: "",
};

export const StyledMainWrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledContainer = styled("div", size)`
  width: 100%;
  ${({ inTheCenter }) => inTheCenter && "max-width: 1470px"};
  margin: 0 auto;
  padding: ${({ inTheCenter }) => (inTheCenter ? "0 14px" : "32px 32px 64px 32px")};
  background-color: ${({ theme }) => theme.bodyBackgroundColor};
  //border-radius: 40px 0 0 0;
  overflow-x: ${({ overflowX }) => overflowX ? overflowX : "hidden"};;
  position: relative;
  flex-grow: 1;

  @media screen and (max-width: 992px) {
    padding: ${({ inTheCenter }) => (inTheCenter ? "0 14px" : "14px 14px 42px")};
    border-radius: 0;
  }
`;

export const StyledContentWrapper = styled.div`
  //padding-top: 25px;
`;

export const StyledInformationContainer = styled.div`
  max-width: ${({ width }) => width + "px" || "none"};
  width: 100%;

  .information {
    padding: 15px;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    gap: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.neutral.white};
    word-break: break-all;

    &__value {
      width: 100%;
      color: ${({ theme }) => theme.primary.first};
      overflow-wrap: break-word;
      word-break: break-word;
    }

    &__key {
      word-break: break-word;
    }
  }

  @media screen and (max-width: 768px) {
    max-width: none;
    .information {
      padding: 12px 0;
      grid-template-columns: 100%;

      &__key {
        padding-bottom: 10px;
        font-weight: 600;
      }
    }
  }
`;

export const StyledTabContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .header-block {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }

  #filter {
    justify-content: flex-start;
  }
  
  .create-button,
  .apply-button {
    min-width: 125px;
  }

  .apply-button {
    border-color: transparent;

    &:hover {
      color: ${({ theme }) => theme.primary.first};
      background-color: ${({ theme }) => theme.primary.third};
    }

    &_active:hover {
      color: ${({ theme }) => theme.neutral.white};
      background-color: ${({ theme }) => theme.primary.first};
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    flex-wrap: unset;

    .create-button,
    .apply-button {
      min-width: 125px;
      width: 100%;
      color: ${({ theme }) => theme.primary.first};
      background-color: ${({ theme }) => theme.primary.third};
      border-color: transparent;

      &_active {
        color: ${({ theme }) => theme.neutral.white};
        background-color: ${({ theme }) => theme.primary.first};
      }
    }
  }
`;
