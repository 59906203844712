import { createSlice } from "@reduxjs/toolkit";

import {
  changePairActivityReducer, changePairMultiForAllReducer,
  changePairMultiReducer,
  changePairPercentReducer, changePairUnitForPairReducer,
  fetchPairsReducer
} from "./pairsReducers";
import { closableNotification } from "../../components/elements/notification/ClosableNotification";
import { t } from "i18next";

const initialState = {
  pairs: [],
  error: null,
  loading: true,
  changePercentState: {
    loading: false,
  },
  changeActivityState: {
    loading: false,
  },
  paginationInfo: {
    totalItems: null,
    totalPageCount: null,
    itemsPerPage: null,
    page: null,
  },
  refreshing: false,
};

const pairsSlice = createSlice({
  name: "pairsSlice",
  initialState,
  reducers: {
    setPaginationInfo: (state, action) => {
      state.paginationInfo = action.payload;
    },
    clearPairStore: (state) => {
      state.pairs = [];
      state.refreshing = false;
    },
    updatePairsStore: (state, action) => {
      const { data, updatedData, callback } = action.payload;
      const [key, value] = Object.entries(updatedData)[0];

      const refreshedPairs = state.pairs.map(item => ({
        ...item,
        [key]: value,
      }));

      state.pairs = refreshedPairs;
      state.refreshing = false;
      closableNotification(t("alerts.multipleEditMsg"), "success");
      callback();
    },
  },
  extraReducers: (builder) => {
    changePairActivityReducer(builder);
    changePairPercentReducer(builder);
    fetchPairsReducer(builder);
    changePairMultiReducer(builder);
    changePairMultiForAllReducer(builder);
    changePairUnitForPairReducer(builder);
  },
});

export const {
  setPaginationInfo,
  clearPairStore,
  updatePairsStore,
} = pairsSlice.actions;

export default pairsSlice.reducer;
