import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../utils/setupAxiosInterceptors";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";

const fetchServices = createAsyncThunk(
  "serviceSlice/fetchServices",
  async (filterUrl, thunkAPI) => {
    try {
      const response = await axiosInstance(`/api/services${filterUrl}`, userAuthenticationConfig());

      return {
        data: response.data["hydra:member"],
        paginationInfo: {
          totalPageCount: response.data["hydra:totalPageCount"],
          totalItems: response.data["hydra:totalItems"],
        },
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

const fetchAvailableServices = createAsyncThunk(
  "serviceSlice/fetchAvailableServices",
  async (filterUrl, thunkAPI) => {
    try {
      const response = await axiosInstance(
        `/api/available-services${filterUrl}`,
        userAuthenticationConfig()
      );

      return {
        data: response.data["hydra:member"],
        paginationInfo: {
          totalPageCount: response.data["hydra:totalPageCount"],
          totalItems: response.data["hydra:totalItems"],
        },
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export { fetchAvailableServices, fetchServices };
