import { crmSidebar } from "../../../rbac-consts";
import { typeAccountRoutes } from "../../../utils/consts";
import { permissionUserEndpoints } from "../../../utils/groupEndpoints/exchanger/user";
import { groupPermissionEndpoints } from "../../../utils/groupEndpoints/groupPermissionEndpoints";

const sidebarRoutes = [
  // {
  //   title: "dashboard",
  //   path: `${typeAccountRoutes.CRM}/dashboard`,
  //   icon: "icon-presentation"
  // },
  {
    title: "exchanges",
    path: `${typeAccountRoutes.CRM}/orders`,
    permission: [
      groupPermissionEndpoints.API_EXCHANGER_ORDERS_GET_COLLECTION,
      groupPermissionEndpoints.API_EXCHANGER_ORDERS_GET_ITEM,
    ],
    icon: "icon-ex-menu-orders",
  },
  {
    title: "widgets",
    path: `${typeAccountRoutes.CRM}/widget`,
    permission: [groupPermissionEndpoints.API_EXCHANGER_WIDGETS_GET_COLLECTION],
    icon: "icon-ex-menu-widgets",
  },
  {
    title: "referralSystem",
    path: `${typeAccountRoutes.CRM}/referral`,
    permission: [permissionUserEndpoints.GET_EXCHANGER_REFERRAL_TOKEN],
    checkFullAccess: true,
    icon: "icon-ex-menu-referral",
  },
  {
    title: "verification",
    path: `${typeAccountRoutes.CRM}/verification`,
    permission: [
      groupPermissionEndpoints.GET_EXCHANGER_USER_VERIFICATIONS,
      groupPermissionEndpoints.POST_EXCHANGER_USER_VERIFICATION,
    ],
    icon: "icon-ex-menu-verification",
  },
  {
    title: "savedRequisites",
    path: `${typeAccountRoutes.CRM}/saved-requisites`,
    permission: [permissionUserEndpoints.GET_EXCHANGER_USER_REQUISITES],
    checkFullAccess: true,
    icon: "icon-ex-menu-requisites",
  },
  {
    title: "exchanger",
    path: `${typeAccountRoutes.CRM}/exchanger`,
    permission: [
      groupPermissionEndpoints.API_EXCHANGERS_GET_COLLECTION,
      groupPermissionEndpoints.API_EXCHANGERS_POST_COLLECTION,
    ],
    icon: "icon-ex-menu-exchangers",
  },
  {
    title: "transaction",
    path: `${typeAccountRoutes.CRM}/transactions`,
    permission: [
      groupPermissionEndpoints.CREATE_PAYMENT,
      groupPermissionEndpoints.CREATE_PAYOUT,
      groupPermissionEndpoints.GET_INVOICES_LIST,
      groupPermissionEndpoints.GET_INVOICE_DETAILS,
    ],
    icon: "icon-ex-menu-transactions",
    perform: crmSidebar.TRANSACTION,
  },
  {
    title: "services",
    path: `${typeAccountRoutes.CRM}/services`,
    permission: [groupPermissionEndpoints.API_SERVICES_GET_COLLECTION],
    icon: "icon-ex-menu-services",
  },
  {
    title: "paymentSystems",
    path: `${typeAccountRoutes.CRM}/payment-systems`,
    permission: [
      groupPermissionEndpoints.API_PAYMENT_SYSTEMS_GET_COLLECTION,
      groupPermissionEndpoints.API_PAIR_UNITS_GET_COLLECTION,
      groupPermissionEndpoints.API_EXCHANGER_PAIR_UNITS_GET_COLLECTION,
    ],
    icon: "icon-ex-menu-payment-system",
  },
  {
    title: "rates",
    path: `${typeAccountRoutes.CRM}/rates`,
    permission: [
      groupPermissionEndpoints.API_EXCHANGER_RATES_GET_COLLECTION,
      groupPermissionEndpoints.API_EXCHANGER_RATES_GET_ITEM,
      groupPermissionEndpoints.API_EXCHANGER_RATES_CHANGE_OUR_MARKUP_ITEM,
      groupPermissionEndpoints.GET_EXCHANGER_RATES,
      groupPermissionEndpoints.API_GET_EXCHANGER_RATES_COLLECTION,
    ],
    icon: "icon-ex-menu-exchange-rate",
  },
  {
    title: "pairs",
    path: `${typeAccountRoutes.CRM}/pairs`,
    permission: [groupPermissionEndpoints.API_EXCHANGER_PAIRS_GET_COLLECTION],
    icon: "icon-ex-menu-pairs",
  },
  {
    title: "balances",
    path: `${typeAccountRoutes.CRM}/balances`,
    permission: [
      groupPermissionEndpoints.API_EXCHANGER_BALANCES_GET_COLLECTION,
      groupPermissionEndpoints.API_BALANCES_GET_COLLECTION,
    ],
    icon: "icon-ex-menu-balances",
  },
  {
    title: "reports",
    path: `${typeAccountRoutes.CRM}/reports`,
    permission: [
      groupPermissionEndpoints.GET_EXCHANGER_ORDERS_REPORT,
      groupPermissionEndpoints.API_EXCHANGER_TRAFFIC_LINKS_GET_COLLECTION,
    ],
    icon: "icon-ex-menu-report",
  },
  {
    title: "servers",
    path: `${typeAccountRoutes.CRM}/servers`,
    permission: [groupPermissionEndpoints.API_SERVERS_GET_COLLECTION],
    icon: "icon-ex-menu-servers",
  },
  {
    title: "exchangerDatabaseConnection",
    path: `${typeAccountRoutes.CRM}/database-exchanger`,
    permission: [groupPermissionEndpoints.API_EXCHANGER_DATABASE_CONNECTIONS_GET_COLLECTION],
    icon: "icon-ex-menu-database",
  },
  {
    title: "users",
    path: `${typeAccountRoutes.CRM}/users`,
    permission: [
      groupPermissionEndpoints.API_USERS_GET_COLLECTION,
      groupPermissionEndpoints.API_EXCHANGER_USERS_GET_COLLECTION,
    ],
    icon: "icon-ex-menu-users",
  },
  {
    title: "withdrawalRequisitions",
    path: `${typeAccountRoutes.CRM}/withdrawal-requisitions`,
    permission: [groupPermissionEndpoints.API_EXCHANGER_WITHDRAWAL_REQUISITION_GET_COLLECTION],
    icon: "icon-ex-menu-withdrawal-request",
    checkFullAccess: true,
  },
  {
    title: "blacklistWallet",
    path: `${typeAccountRoutes.CRM}/black-list-wallets`,
    permission: [
      groupPermissionEndpoints.API_EXCHANGER_BLACK_LIST_WALLETS_GET_COLLECTION,
      groupPermissionEndpoints.API_EXCHANGER_BLACK_LIST_WALLETS_GET_ITEM,
    ],
    icon: "icon-ex-menu-black-list",
    checkFullAccess: true,
  },
  {
    title: "systemCommands",
    path: `${typeAccountRoutes.CRM}/system-commands`,
    permission: [
      groupPermissionEndpoints.FILL_PAIR_UNITS,
      groupPermissionEndpoints.FILL_ENDPOINTS,
      groupPermissionEndpoints.FILL_GROUP_AND_ENDPOINT_PERMISSIONS,
      groupPermissionEndpoints.CHECK_DIFFERENCE_BETWEEN_ENDPOINTS_AND_GROUP_ENDPOINTS,
      groupPermissionEndpoints.UPDATE_DATABASE_SCHEMA,
      groupPermissionEndpoints.UPDATE_DATABASE_SCHEMA_FOR_ALL_EXCHANGERS,
      groupPermissionEndpoints.FILL_BASE_CURRENCY_RATE_SERVICE,
    ],
    icon: "icon-ex-menu-systems",
  },
  {
    title: "settings",
    path: `${typeAccountRoutes.CRM}/settings`,
    permission: [
      groupPermissionEndpoints.API_USERS_CHANGE_PASSWORD,
      groupPermissionEndpoints.GET_EXCHANGER_AUTHENTICATOR_SECRET_IS_ACTIVE,
    ],
    icon: "icon-ex-menu-settings",
  },
  {
    title: "logs",
    path: `${typeAccountRoutes.CRM}/logs`,
    permission: [groupPermissionEndpoints.GET_EXCHANGER_LOGS],
    icon: "icon-ex-menu-logs",
    checkFullAccess: true,
  },
  // TODO links for MS Cash
  // {
  //   title: "msCash",
  //   path: `${typeAccountRoutes.CRM}/mscash`,
  //   permission: [
  //     groupPermissionEndpoints.API_EXCHANGER_ORDERS_GET_COLLECTION,
  //   ],
  //   icon: "icon-ex-menu-cash-operations",
  //   checkFullAccess: true,
  //   subLinks: [
  //     {
  //       title: "msCashSubLink.cashier",
  //       path: `${typeAccountRoutes.CRM}/cash/cashier`,
  //       permission: [
  //         groupPermissionEndpoints.API_EXCHANGER_ORDERS_GET_COLLECTION,
  //       ],
  //       icon: "icon-ex-menu-ashier"
  //     },
  //     {
  //       title: "msCashSubLink.networkSettings",
  //       path: `${typeAccountRoutes.CRM}/cash/network-settings`,
  //       permission: [
  //         groupPermissionEndpoints.API_EXCHANGER_ORDERS_GET_COLLECTION,
  //       ],
  //       icon: "icon-ex-menu-network"
  //     },
  //     {
  //       title: "msCashSubLink.currencySettings",
  //       path: `${typeAccountRoutes.CRM}/cash/currency-settings`,
  //       permission: [
  //         groupPermissionEndpoints.API_EXCHANGER_ORDERS_GET_COLLECTION,
  //       ],
  //       icon: "icon-ex-menu-currency"
  //     },
  //     {
  //       title: "msCashSubLink.cashReports",
  //       path: `${typeAccountRoutes.CRM}/cash/reports`,
  //       permission: [
  //         groupPermissionEndpoints.API_EXCHANGER_ORDERS_GET_COLLECTION,
  //         groupPermissionEndpoints.API_EXCHANGER_ORDERS_GET_ITEM,
  //       ],
  //       icon: "icon-ex-menu-statistic"
  //     },
  //   ]
  // },
];

export default sidebarRoutes;
