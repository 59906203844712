export const permissionManagerEndpoints = {
  PUT_EXCHANGER_CHANGE_AVATAR: {
    name: "put_exchanger_change_avatar",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  PUT_EXCHANGER_REMOVE_AVATAR: {
    name: "put_exchanger_remove_avatar",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_BALANCES_GET_ITEM: {
    name: "api_exchanger_balances_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_INVOICES_GET_COLLECTION: {
    name: "api_exchanger_invoices_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_INVOICES_GET_ITEM: {
    name: "api_exchanger_invoices_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_ORDERS_GET_COLLECTION: {
    name: "api_exchanger_orders_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_ORDERS_GET_ITEM: {
    name: "api_exchanger_orders_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_USER_INFOS_GET_COLLECTION: {
    name: "api_exchanger_user_infos_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_USER_INFOS_GET_ITEM: {
    name: "api_exchanger_user_infos_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_USERS_GET_COLLECTION: {
    name: "api_exchanger_users_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_USERS_GET_ITEM: {
    name: "api_exchanger_users_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  EXCHANGER_PAYMENT_SYSTEM: {
    name: "exchanger_payment_system",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  EXCHANGER_CURRENCY: {
    name: "exchanger_currency",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  EXCHANGER_SERVICE: {
    name: "exchanger_service",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  GET_EXCHANGER_AUTHENTICATOR_SECRET: {
    name: "get_exchanger_authenticator_secret",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  GET_EXCHANGER_AUTHENTICATOR_SECRET_IS_ACTIVE: {
    name: "get_exchanger_authenticator_secret_is_active",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  EXCHANGER_REFRESH_TOKEN: {
    name: "exchanger_refresh_token",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  GET_EXCHANGER_RATE_SERVICES: {
    name: "get_exchanger_rate_services",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  EXCHANGER_UPDATE_ORDER: {
    name: "exchanger_update_order",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  GET_EXCHANGER_MAILING_LANGUAGE: {
    name: "get_exchanger_mailing_language",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  UPDATE_EXCHANGER_MAILING_LANGUAGE: {
    name: "update_exchanger_mailing_language",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
};
