import { createSlice } from "@reduxjs/toolkit";

import {
  changeMyExchangerAvailableServicesReducer,
  changeMyExchangerInputDataReducer,
  changeMyExchangerPairUnitActivityForAllReducer,
  changeMyExchangerPairUnitActivityReducer,
  changeMyExchangerPairUnitMultiForAllReducer,
  changeMyExchangerPairUnitMultiReducer,
  changeMyExchangerPairUnitReducer,
  changePairUnitActivityReducer,
  changePairUnitsMultiActivityForAllReducer,
  changePairUnitsMultiActivityReducer,
  fetchMyExchangerAvailableServicesReducer,
  fetchPairUnitsReducer,
  fetchUserPairUnitsReducer
} from "./pairUnitReducers";
import { closableNotification } from "../../../components/elements/notification/ClosableNotification";
import { t } from "i18next";

const initialState = {
  pairUnits: [],
  error: null,
  loading: true,
  changeActivityState: {
    loading: false,
  },
  changeDataState: {
    loading: false,
  },
  changeAvailableServices: {
    loading: false,
  },
  activeMultiple: [],
  availableServices: [],
  paginationInfo: {
    totalItems: null,
    totalPageCount: null,
    itemsPerPage: null,
    page: null,
  },
  canEditFields: false,
  canEditConstant: false,
  refreshing: false,
};

const pairUnitSlice = createSlice({
  name: "pairUnitSlice",
  initialState,
  reducers: {
    setPaginationInfo: (state, action) => {
      state.paginationInfo = action.payload;
    },
    clearPairUnitStore: (state) => {
      state.pairUnits = [];
      state.loading = true;
      state.error = null;
      state.refreshing = false;
    },
    setAllMultipleId: (state) => {
      if (state.activeMultiple.length === state.pairUnits.length) {
        state.activeMultiple = [];
        return;
      }

      state.activeMultiple = state.pairUnits.map((item) => item.id);
    },
    setItemInMultipleArray: (state, action) => {
      if (!action.payload.isActive) {
        state.activeMultiple.push(action.payload.id);
      } else {
        state.activeMultiple = state.activeMultiple.filter((item) => item !== action.payload.id);
      }
    },
    updateEditPermissions: (state, action) => {
      state.canEditFields = action.payload.canEditFields;
      state.canEditConstant = action.payload.canEditConstant;
    },
    updatePairUnitsStore: (state, action) => {
      const { updatedData, callback } = action.payload;
      const [key, value] = Object.entries(updatedData)[0];

      const refreshedPairUnits = state.pairUnits.map(item => ({
        ...item,
        ...(key === "isActive" && { isActive: value }),
        ...(key !== "isActive" && item.fee && { fee: { ...item.fee, ...updatedData } }),
      }));

      state.pairUnits = refreshedPairUnits;
      state.refreshing = false;
      closableNotification(t("alerts.multipleEditMsg"), "success");
      callback();
    },
  },
  extraReducers: (builder) => {
    fetchPairUnitsReducer(builder);
    changePairUnitActivityReducer(builder);
    fetchUserPairUnitsReducer(builder);
    changeMyExchangerPairUnitActivityReducer(builder);
    changeMyExchangerInputDataReducer(builder);
    changeMyExchangerAvailableServicesReducer(builder);
    fetchMyExchangerAvailableServicesReducer(builder);
    changeMyExchangerPairUnitReducer(builder);
    changeMyExchangerPairUnitMultiReducer(builder);
    changePairUnitsMultiActivityReducer(builder);
    changePairUnitsMultiActivityForAllReducer(builder);
    changeMyExchangerPairUnitMultiForAllReducer(builder);
    changeMyExchangerPairUnitActivityForAllReducer(builder);
    // builder.addCase(putPairUnit.pending, (state, action) => {
    //   state.supportLoading = true;
    // });
    // builder.addCase(putPairUnit.fulfilled, (state, action) => {
    //   state.pairUnits = state.pairUnits.map(item => {
    //     const findElement = action.payload.data.find(element => element.id === item.id);
    //
    //     if (findElement) {
    //       return {
    //         ...item,
    //         ...findElement,
    //         merchantIsActive: findElement.isActive
    //       };
    //     }
    //
    //     return item;
    //   });
    //
    //   closableNotification("Success edit", "success");
    //   state.activeMultiple = []
    //   state.supportLoading = false;
    // });
    // builder.addCase(putPairUnit.rejected, (state, action) => {
    //   errorsParser(action.payload);
    //   state.supportLoading = false;
    // });
  },
});

export const {
  setPaginationInfo,
  clearPairUnitStore,
  setAllMultipleId,
  setItemInMultipleArray,
  updateEditPermissions,
  updatePairUnitsStore,
} = pairUnitSlice.actions;

export default pairUnitSlice.reducer;
