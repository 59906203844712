import { Trans } from "react-i18next";
import CustomLink from "../../elements/customLink/CustomLink";

import { StyledSidebarItem, StyledSidebarLink } from "./styledSidebar";
import { StyledSpan, StyledTextRegular } from "../../styles/styledTypography";

const SidebarItemSubLink = ({ title, path, icon, match }) => {
  return (
    <StyledSidebarLink
      as={CustomLink}
      active={match}
      to={path}
      className={match && "active"}
    >
      <StyledSidebarItem>
        <StyledSpan fontSize="20" className={icon} />
        <StyledTextRegular>
          <Trans>
            {title}
          </Trans>
        </StyledTextRegular>
      </StyledSidebarItem>
    </StyledSidebarLink>
  );
};

export default SidebarItemSubLink;
