import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { toggleMobile } from "../../../store/theme/themeSlice";
import { defaultRoute } from "../../../utils/consts";
import { useMedia } from "../../../utils/customHooks/useMedia";
import CustomLink from "../../elements/customLink/CustomLink";
import LanguageSelect from "../../elements/language/LanguageSelect";
import Logo from "../../elements/logo/Logo";
import Navigation from "../../elements/navigation/Navigation";
import BalanceButton from "../../userBalance/BalanceButton";
import UserInfo from "../../userInfo/UserInfo";
import {
  StyledHeaderContainer,
  StyledHeaderWrapper,
  StyledMobileSideBarIcon,
} from "./styledHeader";

const Header = ({ authenticated, isAccountPathname, t }) => {
  const dispatch = useDispatch();

  const { email, roles } = useSelector((store) => store.user.user) || {};
  const { sidebarToggled } = useSelector((store) => store.theme);

  const isMobile = useMedia("(max-width: 992px)");

  const userAccountLink = () => {
    if (authenticated) {
      return defaultRoute;
    }

    return "/login";
  };

  return (
    <StyledHeaderWrapper isAccountPathname={isAccountPathname}>
      <StyledHeaderContainer>
        <div className="header-left">
          <Logo />
        </div>
        {!isAccountPathname && (
          <Navigation authenticated={authenticated} userAccountLink={userAccountLink} />
        )}
        <div className="header-right">
          {isAccountPathname && (
            <BalanceButton roles={roles} authenticated={authenticated} />
          )}
          {isAccountPathname && authenticated && email && !isMobile && (
            <UserInfo email={email} isMobile={isMobile} />
          )}
          {!isAccountPathname &&
            <CustomLink className="header-right__account header-right__account-pc" to={userAccountLink()}>
              <span className="icon-ex-input-usename" />
              <span className="header-right__account-title">
                {!authenticated ? t("signIn") : t("myAccount")}
              </span>
            </CustomLink>
          }
          <LanguageSelect />
          {!isAccountPathname &&
            <CustomLink className="header-right__account header-right__account-mobile" to={userAccountLink()}>
              <span className="icon-ex-input-usename" />
              <span className="header-right__account-title">
                {!authenticated ? t("signIn") : t("myAccount")}
              </span>
            </CustomLink>
          }
          {isAccountPathname &&
            <StyledMobileSideBarIcon
            authenticated={authenticated}
            show={sidebarToggled}
            onClick={() => dispatch(toggleMobile())}
            >
            <div className="navbar-top" />
            <div className="navbar-middle" />
            <div className="navbar-bottom" />
            </StyledMobileSideBarIcon>
          }
        </div>
      </StyledHeaderContainer>
    </StyledHeaderWrapper>
  );
};

export default withTranslation("translation", { keyPrefix: "navigation" })(Header);
