import { configureStore } from "@reduxjs/toolkit";

import authSlice from "./auth/authSlice";
import balancesSlice from "./balances/balancesSlice";
import billingAddressSlice from "./billingAddress/billingAddressSlice";
import blackListWalletSlice from "./blackListWallet/blackListWalletSlice";
import clientsSlice from "./clients/clientsSlice";
import countriesSlice from "./countries/countriesSlice";
import dbExchangerConnectionsSlice from "./dbExchangerConnections/dbExchangerConnectionsSlice";
import documentsSlice from "./documents/documentsSlice";
import endpointSlice from "./endpoint/endpointSlice";
import exchangerDetailsSlice from "./exchangerDetails/exchangerDetailsSlice";
import exchangersSlice from "./exchangers/exchangersSlice";
import filterHelpersSlice from "./filterHelpers/filterHelpersSlice";
import logsSlice from "./logs/logsSlice";
import orderDetailsSlice from "./orderDetails/orderDetailsSlice";
import ordersSlice from "./orders/ordersSlice";
import pairsSlice from "./pair/pairsSlice";
import exchangerPairUnitSlice from "./pairUnit/exchanger/pairUnitSlice";
import pairUnitSlice from "./pairUnit/main/pairUnitSlice";
import paymentSystemSlice from "./paymentSystem/paymentSystemSlice";
import ratesSlice from "./rates/ratesSlice";
import referralSystemSlice from "./referralSystem/referralSystemSlice";
import referralWidgetSlice from "./referralWidget/referralWidgetSlice";
import reportsSlice from "./reports/reportsSlice";
import savedRequisitesSlice from "./savedRequisites/savedRequisitesSlice";
import serversSlice from "./servers/serversSlice";
import serviceSlice from "./service/serviceSlice";
import systemCommandsSlice from "./systemCommands/systemCommandsSlice";
import themeSlice from "./theme/themeSlice";
import transactionDetailsSlice from "./transactionDetails/exchanger/transactionDetailsSlice";
import transactionsExchangerSlice from "./transactions/exchanger/transactionsSlice";
import userSlice from "./user/userSlice";
import userBalance from "./userBalance/userBalanceSlice";
import widgetSlice from "./widget/widgetSlice";
import withdrawalRequisitionsSlice from "./withdrawalRequisitions/withdrawalRequisitionsSlice";

export default configureStore({
  reducer: {
    auth: authSlice,
    theme: themeSlice,
    user: userSlice,
    clients: clientsSlice,
    services: serviceSlice,
    paymentSystem: paymentSystemSlice,
    pairUnits: pairUnitSlice,
    exchangerPairUnits: exchangerPairUnitSlice,
    pairs: pairsSlice,
    rates: ratesSlice,
    exchangers: exchangersSlice,
    systemCommands: systemCommandsSlice,
    endpoints: endpointSlice,
    orders: ordersSlice,
    databaseExchangerConnections: dbExchangerConnectionsSlice,
    transactions: transactionsExchangerSlice,
    orderDetails: orderDetailsSlice,
    servers: serversSlice,
    exchangerDetails: exchangerDetailsSlice,
    balances: balancesSlice,
    transactionDetails: transactionDetailsSlice,
    filterHelpers: filterHelpersSlice,
    referralSystem: referralSystemSlice,
    referralWidget: referralWidgetSlice,
    widget: widgetSlice,
    documents: documentsSlice,
    savedRequisites: savedRequisitesSlice,
    reports: reportsSlice,
    userBalance: userBalance,
    withdrawalRequisitions: withdrawalRequisitionsSlice,
    blackListWallets: blackListWalletSlice,
    logs: logsSlice,
    billingAddress: billingAddressSlice,
    countries: countriesSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: ["payload.callback", "meta.arg.callback"],
      },
    }),
});
