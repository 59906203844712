import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../utils/setupAxiosInterceptors";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";

const fetchReferralWidgets = createAsyncThunk(
  "referralWidget/fetchReferralWidgets",
  async ({ filterUrl }, thunkAPI) => {
    const url = `/api/widgets${filterUrl}`;

    try {
      const response = await axiosInstance.get(url, userAuthenticationConfig());

      return {
        data: response.data["hydra:member"],
        paginationInfo: {
          totalPageCount: response.data["hydra:totalPageCount"],
          totalItems: response.data["hydra:totalItems"],
        },
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const fetchReferralWidgetById = createAsyncThunk(
  "referralWidget/fetchReferralWidgetById",
  async ({ id }, thunkAPI) => {
    const url = `/api/widgets/${id}`;

    try {
      const response = await axiosInstance.get(url, userAuthenticationConfig());
      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const changeReferralWidgetStatus = createAsyncThunk(
  "referralWidget/changeReferralWidgetStatus",
  async ({ id, status }, thunkAPI) => {
    const url = `/api/widgets/${id}`;
    try {
      const response = await axiosInstance.patch(url, { status }, userAuthenticationConfig());

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const createReferralWidget = createAsyncThunk(
  "referralWidget/createReferralWidget",
  async ({ name, url, callback }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/api/widgets",
        { name, url },
        userAuthenticationConfig()
      );

      return {
        data: response.data,
        callback,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const fetchWidgetPairs = createAsyncThunk(
  "referralWidget/fetchWidgetPairs",
  async ({ filterUrl }, thunkAPI) => {
    try {
      const response = await axiosInstance(
        `/api/exchanger-widget-pairs${filterUrl}`,
        userAuthenticationConfig()
      );

      return {
        data: response.data["hydra:member"],
        paginationInfo: {
          totalPageCount: response.data["hydra:totalPageCount"],
          totalItems: response.data["hydra:totalItems"],
        },
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const changeWidgetPair = createAsyncThunk(
  "referralWidget/changeWidgetPair",
  async ({ id, data, notificationsCallback }, thunkAPI) => {
    try {
      const response = await axiosInstance.put(
        `/api/exchanger-widget-pairs/${id}`,
        data,
        userAuthenticationConfig()
      );

      return {
        data: response.data,
        notificationsCallback,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const changeWidgetPairOurPercent = createAsyncThunk(
  "referralWidget/changeWidgetPairOurPercent",
  async ({ id, data, notificationsCallback }, thunkAPI) => {
    try {
      const response = await axiosInstance.patch(
        `/api/exchanger-widget-pairs/${id}/change-our-percent`,
        data,
        userAuthenticationConfig(true)
      );

      return {
        data: response.data,
        notificationsCallback,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const changeWidgetSettings = createAsyncThunk(
  "referralWidget/changeWidgetSettings",
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        `/api/exchanger-widget-settings-change/${id}`,
        { ...data },
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const fetchWidgetReports = createAsyncThunk(
  "referralWidget/fetchWidgetReports",
  async ({ id, filterUrl }, thunkAPI) => {
    try {
      const response = await axiosInstance(
        `/api/exchanger-widget-reports/${id}${filterUrl}`,
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const updateWidgetReports = createAsyncThunk(
  "referralWidget/updateWidgetReports",
  async ({ data, token }, thunkAPI) => {
    try {
      await axiosInstance.post(
        `/api/exchanger-widget-reports/update?token=${token}`,
        data,
        userAuthenticationConfig()
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const changeReferralWidgetCustomFee = createAsyncThunk(
  "referralWidget/changeReferralWidgetCustomFee",
  async ({ id, isEnabledCustomFee }, thunkAPI) => {
    const url = `/api/widgets/${id}`;
    try {
      const response = await axiosInstance.patch(
        url,
        { isEnabledCustomFee },
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const changeWidgetPairMulti = createAsyncThunk(
  "referralWidget/changeWidgetPairMulti",
  async ({ id, widgetPairs, ...data }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        `/api/widget-pairs/update/${id}`,
        { ...data, widgetPairs },
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const changeWidgetPairMultiForAll = createAsyncThunk(
  "referralWidget/changeWidgetPairMultiForAll",
  async ({ id, applyToAll, ...data }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        `/api/widget-pairs/update/${id}`,
        { ...data, applyToAll },
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const showWidgetSecret = createAsyncThunk(
  "referralWidget/showWidgetSecret",
  async ({ twoFa, widgetId }, thunkAPI) => {
    const url = `/api/exchanger-widgets/${widgetId}/get-secret?twofa=${twoFa}`;

    try {
      const response = await axiosInstance.get(url, userAuthenticationConfig());
      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const regenerateWidgetSecret = createAsyncThunk(
  "referralWidget/regenerateWidgetSecret",
  async ({ twoFa, widgetId }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/api/exchanger-widgets/regenerate-secret",
        {
          twofa: twoFa,
          widgetId: widgetId,
        },
        userAuthenticationConfig()
      );

      return {
        data: response.data.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const changeWidgetPairUnitForWidgetPair = createAsyncThunk(
  "referralWidget/changeWidgetPairUnitForWidgetPair",
  async (data, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/api/exchanger-widget-pairs/change-pair-unit-for-widget-pairs",
        data,
        userAuthenticationConfig()
      );

      return {
        bodyRequest: data,
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const fetchWidgetOrders = createAsyncThunk(
  "referralWidget/fetchWidgetOrders",
  async ({ filterUrl, widgetId }, thunkAPI) => {
    try {
      const response = await axiosInstance(
        `/api/exchanger-widget-orders/${widgetId}${filterUrl}`,
        userAuthenticationConfig()
      );

      return {
        data: response.data,
        // paginationInfo: {
        //   totalPageCount: response.data["hydra:totalPageCount"],
        //   totalItems: response.data["hydra:totalItems"],
        // },
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

export {
  changeReferralWidgetCustomFee,
  changeReferralWidgetStatus,
  changeWidgetPair,
  changeWidgetPairMulti,
  changeWidgetPairMultiForAll,
  changeWidgetPairOurPercent,
  changeWidgetPairUnitForWidgetPair,
  changeWidgetSettings,
  createReferralWidget,
  fetchReferralWidgetById,
  fetchReferralWidgets,
  fetchWidgetOrders,
  fetchWidgetPairs,
  fetchWidgetReports,
  regenerateWidgetSecret,
  showWidgetSecret,
  updateWidgetReports,
};
