import React, { lazy, Suspense } from "react";

import withPermissionCheckPage from "../../utils/customHOC/withPermissionCheckPage";
import { suspenseFallbackFactory } from "./suspenseFallbackFactory";

const withPermissionCheck = (pageComponentPath, permission, cookiesLang, checkFullAccess, dispatch) => {
  return withPermissionCheckPage(
    lazy(() => import(`../../pages${pageComponentPath}`)),
    permission,
    cookiesLang,
    checkFullAccess,
    dispatch
  );
};

const SuspenseWrapper = ({
  pageComponentPath,
  permission,
  cookiesLang,
  checkFullAccess = false,
  dispatch,
}) => {
  const LazyComponent = withPermissionCheck(
    pageComponentPath,
    permission, cookiesLang,
    checkFullAccess,
    dispatch
  );

  return (
    <Suspense fallback={suspenseFallbackFactory(pageComponentPath)}>
      <LazyComponent />
    </Suspense>
  );
};

export default React.memo(SuspenseWrapper);
