import { StyledCalculatorLoader } from "./styledCalculatorLoader";
import Spinner from "../spinner/Spinner";

const CalculatorLoader = ({ widgetLogo, widgetLayout }) => {
  return (
    <StyledCalculatorLoader widgetLogo={widgetLogo} widgetLayout={widgetLayout}>
      <div id="extopWidget">
        <div className="extop-widget-loader">
          {/*<StyledLoadingSpinner />*/}
          <Spinner size="35px" />
        </div>
      </div>
    </StyledCalculatorLoader>
  );
};

export default CalculatorLoader;