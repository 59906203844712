import { t } from "i18next";

import { closableNotification } from "../../components/elements/notification/ClosableNotification";
import { errorsParser } from "../../utils/errorsParser";
import {
  addExchangerCPConnection,
  changeCalculatorSettings,
  changeExchangerServiceStatus,
  changeExchangerSettings,
  changeMonthlyLimit,
  changeWidgetStatusExchanger,
  deleteCalculatorSettings,
  fetchCalculatorSettings,
  fetchExchangerDetails,
  fetchExchangerServices, fetchExchangerSettings,
  fillExchangerConnections,
  getExchangerCPProjectId,
  getExchangerSecretCPConnection,
  putStatusExchanger,
} from "./asyncExchangerDetails";

const fetchExchangerDetailsReducer = (builder) => {
  builder.addCase(fetchExchangerDetails.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(fetchExchangerDetails.fulfilled, (state, action) => {
    state.exchanger = action.payload.data;
    state.loading = false;
  });
  builder.addCase(fetchExchangerDetails.rejected, (state, action) => {
    errorsParser(action.payload, true);
    state.loading = false;
  });
};

const fetchExchangerServicesReducer = (builder) => {
  builder.addCase(fetchExchangerServices.pending, (state) => {
    state.services.loading = true;
  });
  builder.addCase(fetchExchangerServices.fulfilled, (state, action) => {
    state.services.list = action.payload.data;
    state.services.loading = false;
  });
  builder.addCase(fetchExchangerServices.rejected, (state, action) => {
    errorsParser(action.payload, true);
    state.services.loading = false;
  });
};

const changeExchangerSettingsReducer = (builder) => {
  builder.addCase(changeExchangerSettings.pending, (state) => {
    state.settings.loading = true;
  });
  builder.addCase(changeExchangerSettings.fulfilled, (state, action) => {
    closableNotification(t("alerts.exchangerSettingsUpdated"), "success");
    state.exchanger.exchangerSettings = action.payload.data;
    state.settings.data = action.payload.data;
    state.settings.loading = false;
  });
  builder.addCase(changeExchangerSettings.rejected, (state, action) => {
    errorsParser(action.payload);
    state.settings.loading = false;
  });
};

const addExchangerCPConnectionReducer = (builder) => {
  builder.addCase(addExchangerCPConnection.pending, (state) => {
    state.cpConnection.loading = true;
  });
  builder.addCase(addExchangerCPConnection.fulfilled, (state) => {
    closableNotification(t("alerts.exchangerSettingsUpdated"), "success");
    state.cpConnection.loading = false;
    // todo set new data from response
  });
  builder.addCase(addExchangerCPConnection.rejected, (state, action) => {
    errorsParser(action.payload);
    state.cpConnection.loading = false;
  });
};

const getExchangerSecretCPConnectionReducer = (builder) => {
  builder.addCase(getExchangerSecretCPConnection.pending, (state) => {
    state.cpConnection.loading = true;
  });
  builder.addCase(getExchangerSecretCPConnection.fulfilled, (state, action) => {
    state.cpConnection.loading = false;
    state.cpConnection.secret = action.payload.data?.connectionFields?.secret;
  });
  builder.addCase(getExchangerSecretCPConnection.rejected, (state, action) => {
    errorsParser(action.payload);
    state.cpConnection.loading = false;
  });
};

const getExchangerCPProjectIdReducer = (builder) => {
  builder.addCase(getExchangerCPProjectId.pending, (state) => {
    state.cpConnection.loading = true;
  });
  builder.addCase(getExchangerCPProjectId.fulfilled, (state, action) => {
    state.cpConnection.loading = false;
    state.cpConnection.projectId = action.payload.data.projectId;
    state.cpConnection.secret = "";
  });
  builder.addCase(getExchangerCPProjectId.rejected, (state, action) => {
    errorsParser(action.payload);
    state.cpConnection.loading = false;
  });
};

const fillExchangerConnectionsReducer = (builder) => {
  builder.addCase(fillExchangerConnections.pending, (state) => {
    state.exchangerFillConnectionsLoading = true;
  });
  builder.addCase(fillExchangerConnections.fulfilled, (state) => {
    closableNotification(t("alerts.exchangerConnectionsFilled"), "success");
    state.exchangerFillConnectionsLoading = false;
  });
  builder.addCase(fillExchangerConnections.rejected, (state, action) => {
    errorsParser(action.payload);
    state.exchangerFillConnectionsLoading = false;
  });
};

const changeExchangerServiceStatusReducer = (builder) => {
  builder.addCase(changeExchangerServiceStatus.pending, (state) => {
    state.services.changeStatusLoading = true;
  });
  builder.addCase(changeExchangerServiceStatus.fulfilled, (state, action) => {
    const newObjectExchanger = action.payload.data;
    state.services.list = state.services.list.map((item) => {
      if (item.id === newObjectExchanger.id) {
        return newObjectExchanger;
      }
      return item;
    });

    closableNotification(t("alerts.serviceConnectionsUpdated"), "success");
    state.services.changeStatusLoading = false;
  });
  builder.addCase(changeExchangerServiceStatus.rejected, (state, action) => {
    errorsParser(action.payload, true);
    state.services.changeStatusLoading = false;
  });
};

const changeWidgetStatusExchangerReducer = (builder) => {
  builder.addCase(changeWidgetStatusExchanger.fulfilled, (state, action) => {
    const widgetStatus = action.payload.data?.widgetStatus;

    state.exchanger = { ...state.exchanger, widgetStatus: widgetStatus };

    state.errors = null;
    closableNotification(t("alerts.widgetStatusChanged"), "success");
  });
  builder.addCase(changeWidgetStatusExchanger.rejected, (state, action) => {
    errorsParser(action.payload);
  });
};

const putStatusExchangerReducer = (builder) => {
  builder.addCase(putStatusExchanger.pending, (state) => {
    state.changeExchangerStatusStateLoading = true;
  });
  builder.addCase(putStatusExchanger.fulfilled, (state, action) => {
    const status = action.payload.data?.status;

    state.exchanger = { ...state.exchanger, status };
    state.changeExchangerStatusStateLoading = false;

    closableNotification(t("alerts.statusChanged"), "success");
  });
  builder.addCase(putStatusExchanger.rejected, (state, action) => {
    errorsParser(action.payload);
    state.changeExchangerStatusStateLoading = false;
  });
};

const fetchCalculatorSettingsReducer = (builder) => {
  builder.addCase(fetchCalculatorSettings.pending, (state) => {
    state.calculatorSettings.loading = true;
  });
  builder.addCase(fetchCalculatorSettings.fulfilled, (state, action) => {
    state.calculatorSettings.data = action.payload.data;
    state.calculatorSettings.loading = false;
  });
  builder.addCase(fetchCalculatorSettings.rejected, (state, action) => {
    errorsParser(action.payload);
    state.calculatorSettings.loading = false;
  });
};

const changeCalculatorSettingsReducer = (builder) => {
  builder.addCase(changeCalculatorSettings.pending, (state) => {
    state.calculatorSettings.changeCalculatorSettingsLoading = true;
  });
  builder.addCase(changeCalculatorSettings.fulfilled, (state, action) => {
    closableNotification(t("alerts.calculatorSettingUpdated"), "success");
    state.calculatorSettings.data = action.payload.data;
    state.calculatorSettings.changeCalculatorSettingsLoading = false;
  });
  builder.addCase(changeCalculatorSettings.rejected, (state, action) => {
    errorsParser(action.payload);
    state.calculatorSettings.changeCalculatorSettingsLoading = false;
  });
};

const deleteCalculatorSettingsReducer = (builder) => {
  builder.addCase(deleteCalculatorSettings.pending, (state) => {
    state.calculatorSettings.changeCalculatorSettingsLoading = true;
  });
  builder.addCase(deleteCalculatorSettings.fulfilled, (state, action) => {
    closableNotification(t("alerts.calculatorSettingDeleted"), "success");
    state.calculatorSettings.data = state.calculatorSettings.data.filter(
      (item) => item.id !== action.payload.id
    );
    state.calculatorSettings.changeCalculatorSettingsLoading = false;
  });
  builder.addCase(deleteCalculatorSettings.rejected, (state, action) => {
    errorsParser(action.payload);
    state.calculatorSettings.changeCalculatorSettingsLoading = false;
  });
};

const changeMonthlyLimitReducer = (builder) => {
  builder.addCase(changeMonthlyLimit.pending, (state) => {
    state.changeMonthlyLimitLoading = true;
  });
  builder.addCase(changeMonthlyLimit.fulfilled, (state, action) => {
    closableNotification(t("alerts.monthlyLimitUpdated"), "success");
    state.changeMonthlyLimitLoading = false;

    state.exchanger.exchangerSettings = state.exchanger.exchangerSettings.map((item) => {
      if (item.name === action.payload.data.name) {
        return action.payload.data;
      }

      return item;
    });
  });
  builder.addCase(changeMonthlyLimit.rejected, (state, action) => {
    errorsParser(action.payload);
    state.changeMonthlyLimitLoading = false;
  });
};

const fetchExchangerSettingsReducer = (builder) => {
  builder.addCase(fetchExchangerSettings.pending, (state) => {
    state.settings.loading = true;
  });
  builder.addCase(fetchExchangerSettings.fulfilled, (state, action) => {
    state.settings.data = action.payload.data;
    state.settings.loading = false;
  });
  builder.addCase(fetchExchangerSettings.rejected, (state, action) => {
    errorsParser(action.payload, true);
    state.settings.loading = false;
  });
};

export {
  addExchangerCPConnectionReducer,
  changeCalculatorSettingsReducer,
  changeExchangerServiceStatusReducer,
  changeExchangerSettingsReducer,
  changeMonthlyLimitReducer,
  changeWidgetStatusExchangerReducer,
  deleteCalculatorSettingsReducer,
  fetchCalculatorSettingsReducer,
  fetchExchangerDetailsReducer,
  fetchExchangerServicesReducer,
  fillExchangerConnectionsReducer,
  getExchangerCPProjectIdReducer,
  getExchangerSecretCPConnectionReducer,
  putStatusExchangerReducer,
  fetchExchangerSettingsReducer,
};
