import { createSlice } from "@reduxjs/toolkit";

import { errorsParser } from "../../utils/errorsParser";
import { fetchAvailableServices, fetchServices } from "./asyncServiceSlice";

const initialState = {
  services: [],
  error: null,
  loading: true,
  paginationInfo: {
    totalItems: null,
    totalPageCount: null,
    itemsPerPage: null,
    page: null,
  },
};

const serviceSlice = createSlice({
  name: "serviceSlice",
  initialState,
  reducers: {
    setPaginationInfo: (state, action) => {
      state.paginationInfo = action.payload;
    },
    clearStateServices: (state) => {
      state.services = [];
      state.loading = true;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchServices.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchServices.fulfilled, (state, action) => {
      state.services = action.payload.data;
      state.paginationInfo = { ...state.paginationInfo, ...action.payload.paginationInfo };
      state.loading = false;
    });
    builder.addCase(fetchServices.rejected, (state, action) => {
      errorsParser(action.payload);
      state.loading = false;
    });

    builder.addCase(fetchAvailableServices.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAvailableServices.fulfilled, (state, action) => {
      state.services = action.payload.data;
      state.loading = false;
    });
    builder.addCase(fetchAvailableServices.rejected, (state, action) => {
      errorsParser(action.payload);
      state.loading = false;
    });
  },
});

export const { setPaginationInfo, clearStateServices } = serviceSlice.actions;

export default serviceSlice.reducer;
