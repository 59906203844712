import React from "react";

import { checkPermission } from "../../../utils/customFunc/checkPermission";
import withPermissionCheckComponent from "../../../utils/customHOC/withPermissionCheckComponent";
import SidebarItem from "./SidebarItem";
import sidebarRoutes from "./sidebarRoutes";

const SidebarLinkList = ({ isUserExchangerAdmin, isExchanger }) => {
  return sidebarRoutes.map((route, index) => {
    if (route?.checkFullAccess) {
      const isHaveFullAccess = checkPermission(route?.permission);
      if (!isHaveFullAccess) {
        return;
      }
    }

    if (route?.customCondition && route?.customCondition({ isUserExchangerAdmin, isExchanger })) {
      return;
    }

    const SidebarItemWithPerm = withPermissionCheckComponent(SidebarItem, route?.permission);

    return (
      <SidebarItemWithPerm
        key={index}
        to={route?.path}
        icon={route.icon}
        title={route.title}
        perform={route.perform}
        subLinks={route?.subLinks}
      />
    );
  });
};

export default SidebarLinkList;
