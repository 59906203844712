import { t } from "i18next";

import { closableNotification } from "../../../components/elements/notification/ClosableNotification";
import { errorsParser } from "../../../utils/errorsParser";
import {
  changeAvailableServices,
  changeExchangerPairUnit,
  changeExchangerPairUnitActivity,
  changeExchangerPairUnitActivityForAll,
  changeExchangerPairUnitInputData,
  changeExchangerPairUnitMulti,
  changeExchangerPairUnitMultiForAll,
  fetchAvailableServices,
  fetchExchangerPairUnits,
  fetchExchangerPairUnitsTab,
} from "./asyncPairUnitSlice";

const fetchExchangerPairUnitsReducer = (builder) => {
  builder.addCase(fetchExchangerPairUnits.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(fetchExchangerPairUnits.fulfilled, (state, action) => {
    state.pairUnits = Object.values(action.payload.data);
    state.paginationInfo = { ...state.paginationInfo, ...action.payload.paginationInfo };
    state.loading = false;
  });
  builder.addCase(fetchExchangerPairUnits.rejected, (state, action) => {
    errorsParser(action.payload);
    state.loading = false;
  });
};

const changeExchangerPairUnitActivityReducer = (builder) => {
  builder.addCase(changeExchangerPairUnitActivity.pending, (state) => {
    state.changeActivityState.loading = true;
  });
  builder.addCase(changeExchangerPairUnitActivity.fulfilled, (state, action) => {
    const newArrayPair = action.payload.data.data;

    state.pairUnits = state.pairUnits.map((item) => {
      const updatedPair = newArrayPair.find((newItem) => newItem.id === item.id);

      if (updatedPair) {
        return {
          ...item,
          exchangerPairUnitId: updatedPair.exchangerPairUnitId,
          isActive: updatedPair.isActive,
          fee: updatedPair.fee,
        };
      }

      return item;
    });

    closableNotification(t("alerts.paymentSystemActivityChanged"), "success");
    state.errors = null;
    state.changeActivityState.loading = false;
  });
  builder.addCase(changeExchangerPairUnitActivity.rejected, (state, action) => {
    errorsParser(action.payload);
    state.changeActivityState.loading = false;
  });
};

const changeExchangerPairUnitActivityForAllReducer = (builder) => {
  builder.addCase(changeExchangerPairUnitActivityForAll.pending, (state) => {
    state.changeActivityState.loading = true;
    state.refreshing = true;
  });
  builder.addCase(changeExchangerPairUnitActivityForAll.fulfilled, (state, action) => {
    const { data } = action.payload;

    closableNotification(data, "success");
    state.refreshing = true;
    state.errors = null;
    state.changeActivityState.loading = false;
  });
  builder.addCase(changeExchangerPairUnitActivityForAll.rejected, (state, action) => {
    errorsParser(action.payload);
    state.changeActivityState.loading = false;
    state.refreshing = false;
  });
};

const changeExchangerPairUnitMultiReducer = (builder) => {
  builder.addCase(changeExchangerPairUnitMulti.pending, (state) => {
    state.changeActivityState.loading = true;
    state.changeDataState.loading = true;
  });
  builder.addCase(changeExchangerPairUnitMulti.fulfilled, (state, action) => {
    const newArrayPair = action.payload.data;

    state.pairUnits = state.pairUnits.map((item) => {
      const updatedPair = newArrayPair.find((newItem) => newItem.id === item.id);

      if (updatedPair) {
        return {
          ...item,
          priority: updatedPair.priority,
          baseCurrencyRateServiceName: updatedPair.baseCurrencyRateServiceName,
          //exchangerPairUnitId: updatedPair.exchangerPairUnitId,
          fee: updatedPair.fee,
        };
      }

      return item;
    });

    closableNotification(t("alerts.allPairUnitsUpdated"), "success");
    state.errors = null;
    state.changeActivityState.loading = false;
    state.changeDataState.loading = false;
  });
  builder.addCase(changeExchangerPairUnitMulti.rejected, (state, action) => {
    errorsParser(action.payload);
    state.changeActivityState.loading = false;
    state.changeDataState.loading = false;
  });
};

const changeExchangerPairUnitMultiForAllReducer = (builder) => {
  builder.addCase(changeExchangerPairUnitMultiForAll.pending, (state) => {
    state.changeActivityState.loading = true;
    state.refreshing = true;
  });
  builder.addCase(changeExchangerPairUnitMultiForAll.fulfilled, (state, action) => {
    const { data } = action.payload;

    closableNotification(data, "success");
    state.refreshing = true;
    state.errors = null;
    state.changeActivityState.loading = false;
  });
  builder.addCase(changeExchangerPairUnitMultiForAll.rejected, (state, action) => {
    errorsParser(action.payload);
    state.changeActivityState.loading = false;
    state.refreshing = false;
  });
};

const fetchExchangerPairUnitsTabsReducer = (builder) => {
  builder.addCase(fetchExchangerPairUnitsTab.pending, (state) => {
    state.pairUnitsTab.loading = true;
  });
  builder.addCase(fetchExchangerPairUnitsTab.fulfilled, (state, action) => {
    state.pairUnitsTab.list = action.payload.data;
    state.pairUnitsTab.loading = false;
  });
  builder.addCase(fetchExchangerPairUnitsTab.rejected, (state, action) => {
    errorsParser(action.payload);
    state.pairUnitsTab.loading = false;
  });
};

const changeExchangerPairUnitReducer = (builder) => {
  builder.addCase(changeExchangerPairUnit.pending, (state) => {
    state.changeDataState.loading = true;
  });
  builder.addCase(changeExchangerPairUnit.fulfilled, (state) => {
    closableNotification(t("alerts.valueSuccessfullyUpdated"), "success");
    state.errors = null;
    state.changeDataState.loading = false;
  });
  builder.addCase(changeExchangerPairUnit.rejected, (state, action) => {
    errorsParser(action.payload);
    state.changeDataState.loading = false;
  });
};

const changeExchangerPairUnitInputDataReducer = (builder) => {
  builder.addCase(changeExchangerPairUnitInputData.pending, (state) => {
    state.changeDataState.loading = true;
  });
  builder.addCase(changeExchangerPairUnitInputData.fulfilled, (state, action) => {
    state.availableServicesList = action.payload;
    state.changeDataState.loading = false;
    closableNotification(t("alerts.valueSuccessfullyUpdated"), "success");
  });
  builder.addCase(changeExchangerPairUnitInputData.rejected, (state, action) => {
    state.changeDataState.loading = false;
    errorsParser(action.payload);
  });
};

const fetchAvailableServicesReducer = (builder) => {
  builder.addCase(fetchAvailableServices.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(fetchAvailableServices.fulfilled, (state, action) => {
    state.availableServices = action.payload.data;
  });
  builder.addCase(fetchAvailableServices.rejected, (state, action) => {
    errorsParser(action.payload);
    state.loading = false;
  });
};

const changeAvailableServicesReducer = (builder) => {
  builder.addCase(changeAvailableServices.pending, (state) => {
    state.changeAvailableServices.loading = true;
  });
  builder.addCase(changeAvailableServices.fulfilled, (state) => {
    closableNotification(t("alerts.paymentSystemTabChanged"), "success");
    state.errors = null;
    state.changeAvailableServices.loading = false;
  });
  builder.addCase(changeAvailableServices.rejected, (state, action) => {
    errorsParser(action.payload);
    state.changeAvailableServices.loading = false;
  });
};

export {
  changeAvailableServicesReducer,
  changeExchangerPairUnitActivityForAllReducer,
  changeExchangerPairUnitActivityReducer,
  changeExchangerPairUnitInputDataReducer,
  changeExchangerPairUnitMultiForAllReducer,
  changeExchangerPairUnitMultiReducer,
  changeExchangerPairUnitReducer,
  fetchAvailableServicesReducer,
  fetchExchangerPairUnitsReducer,
  fetchExchangerPairUnitsTabsReducer,
};
