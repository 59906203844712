import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../utils/setupAxiosInterceptors";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";

const fetchExchangerCountries = createAsyncThunk(
  "countries/fetchExchangerCountries",
  async (thunkAPI) => {
    try {
      const response = await axiosInstance("/api/exchanger-countries", userAuthenticationConfig());

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

export { fetchExchangerCountries };
