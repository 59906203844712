import { createSlice } from "@reduxjs/toolkit";

import { fetchExchangerCountriesReducer } from "./countriesReducer";

const initialState = {
  countries: [],
  errors: null,
  loading: true,
};

const countriesSlice = createSlice({
  name: "countries",
  initialState,
  reducers: {
    clearStateCountries: (state) => {
      state.countries = [];
      state.errors = null;
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    fetchExchangerCountriesReducer(builder);
  },
});

export const { clearStateCountries } = countriesSlice.actions;

export default countriesSlice.reducer;
