import { createSlice } from "@reduxjs/toolkit";

import {
  changePairPercentReducer,
  changeReferralWidgetCustomFeeReducer,
  changeReferralWidgetStatusReducer,
  changeWidgetPairMultiForAllReducer,
  changeWidgetPairMultiReducer,
  changeWidgetSettingsReducer,
  createReferralWidgetReducer,
  fetchReferralWidgetByIdReducer,
  fetchReferralWidgetsReducer,
  fetchWidgetPairsReducer,
  fetchWidgetReportsReducer,
  showWidgetSecretReducer,
  regenerateWidgetSecretReducer,
  changeWidgetPairReducer,
  changeWidgetPairUnitForWidgetPairReducer,
  fetchWidgetOrdersReducer,
} from "./referralWidgetReducer";
import { closableNotification } from "../../components/elements/notification/ClosableNotification";
import { t } from "i18next";

const initialState = {
  widgets: [],
  errors: null,
  loading: true,
  widget: {},
  widgetDetailsLoading: true,
  createWidgetLoading: false,
  secret: null,
  pairs: {
    data: [],
    loading: false,
    errors: null,
    changeParamsLoading: false,
  },
  orders: {
    data: [],
    loading: false,
    errors: null,
  },
  changeWidgetSettingsLoading: false,
  changeWidgetStatusLoading: false,
  changeCustomFeeStatusLoading: false,
  paginationInfo: {
    totalItems: null,
    totalPageCount: null,
    itemsPerPage: null,
    page: null,
  },
  reports: {
    data: [],
    loading: false,
    errors: null,
  },
  refreshing: false,
};

const referralWidgetSlice = createSlice({
  name: "referralWidget",
  initialState,
  reducers: {
    clearStateWidgets: (state) => {
      state.widgets = [];
      state.loading = true;
      state.errors = null;
      state.refreshing = false;
    },
    clearStateWidgetDetails: (state) => {
      state.widget = {};
      state.widgetDetailsLoading = true;
      state.errors = null;
      state.pairs = {};
      state.refreshing = false;
    },
    clearWidgetPairsState: (state) => {
      state.pairs = {};
      state.refreshing = false;
    },
    clearReportsState: (state) => {
      state.reports = {};
    },
    setPaginationInfo: (state, action) => {
      state.paginationInfo = action.payload;
    },
    updateReferralWidgetPairsStore: (state, action) => {
      const { data, updatedData, callback } = action.payload;
      const [key, value] = Object.entries(updatedData)[0];

      const refreshedPairs = state.pairs.data.map(item => ({
        ...item,
        [key]: value,
      }));
      state.pairs.data = refreshedPairs;
      state.refreshing = false;
      closableNotification(t("alerts.multipleEditMsg"), "success");
      callback();
    },
  },
  extraReducers: (builder) => {
    fetchReferralWidgetsReducer(builder);
    createReferralWidgetReducer(builder);
    fetchReferralWidgetByIdReducer(builder);
    fetchWidgetPairsReducer(builder);
    changeWidgetPairReducer(builder);
    changePairPercentReducer(builder);
    changeReferralWidgetStatusReducer(builder);
    changeWidgetSettingsReducer(builder);
    fetchWidgetReportsReducer(builder);
    changeReferralWidgetCustomFeeReducer(builder);
    changeWidgetPairMultiReducer(builder);
    changeWidgetPairMultiForAllReducer(builder);
    showWidgetSecretReducer(builder);
    regenerateWidgetSecretReducer(builder);
    changeWidgetPairUnitForWidgetPairReducer(builder);
    fetchWidgetOrdersReducer(builder);
  },
});

export const {
  clearStateWidgets,
  clearStateWidgetDetails,
  setPaginationInfo,
  clearWidgetPairsState,
  clearReportsState,
  updateReferralWidgetPairsStore,
} = referralWidgetSlice.actions;

export default referralWidgetSlice.reducer;
