import { useMemo } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";

import USDTIcon from "../../assets/images/payment-system-icons/USDT.svg";
import { handleSidebarToggled } from "../../store/theme/themeSlice";
import CustomLink from "../elements/customLink/CustomLink";
import { exchangerRoles } from "../../utils/consts";
import { checkPermissionByName } from "../../utils/customFunc/checkPermission";
import { groupPermissionEndpoints } from "../../utils/groupEndpoints/groupPermissionEndpoints";

const BalanceButton = ({ roles, authenticated, setToggled }) => {
  const dispatch = useDispatch();

  const { profits, profitLoading } = useSelector((state) => state.userBalance);

  const isUserExchangerClient = roles?.some((role) => role === exchangerRoles.USER);

  const haveAccess = useMemo(
    () => checkPermissionByName([groupPermissionEndpoints.API_EXCHANGER_BALANCES]),
    [authenticated]
  );

  const getProfitByType = (searchedType) => profits?.find(({ type }) => type === searchedType)?.amount;

  const totalAmount = getProfitByType("actual");

  const handleClick = () => {
    if (setToggled) {
      dispatch(handleSidebarToggled());
    }
  };

  if (!isUserExchangerClient || !haveAccess) {
    return null;
  }

  return (
    <CustomLink className={`balance header-right__balance ${!totalAmount && "neutral-fivth" }`} to="/crm/balance" onClick={handleClick}>
      <LazyLoadImage src={USDTIcon} alt="usdt" />
      <p>{profitLoading || !totalAmount ? "0.00" : (+totalAmount).toFixed(2)}</p>
    </CustomLink>
  );
};

export default BalanceButton;
