import React, { useMemo } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

import Layout from "../../components/layout/Layout";
import LoginPage from "../../pages/login/LoginPage";
import RegistrationPage from "../../pages/registration/RegistrationPage";
import {
  defaultRoute,
  defaultSystemAdminRoute,
  mainRoles,
  typeAccountRoutes,
} from "../../utils/consts";
import { checkPrivateRouters } from "../../utils/customFunc/checkPrivateRouters";
import { getLocalizedLink } from "../../utils/customFunc/getLocalizedLink";
import getUserInfo from "../../utils/getUserInfo";
import SuspenseWrapper from "./SuspenseWrapper";

const RouterBlock = ({ isAccountPathname }) => {
  const [cookies] = useCookies(["i18next"]);
  const { authenticated, endpointPermissions } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const systemAdminRole = getUserInfo()?.roles?.includes(mainRoles.SYSTEM_ADMIN);

  const { routers } = useMemo(
    () => checkPrivateRouters(authenticated),
    [authenticated]
  );

  const loginElement =
    authenticated && !!endpointPermissions ? (
      <Navigate to={getLocalizedLink("/")} />
    ) : (
      <LoginPage />
    );

  return (
    <Routes>
      {!authenticated ? (
        <Route
          path="/:locale?/x"
          element={<Navigate to={getLocalizedLink("/login")} replace={true} />}
        />
      ) : (
        <Route
          path="/:locale?/x"
          element={
            <Navigate
              to={getLocalizedLink(!systemAdminRole ? defaultRoute : defaultSystemAdminRoute)}
              replace={true}
            />
          }
        />
      )}
      <Route
        path="/"
        element={
          <Layout
            routers={routers}
            authenticated={authenticated}
            isAccountPathname={isAccountPathname}
          />
        }
      >
        <Route path="/:locale?/x/login" element={loginElement} />
        <Route path="/:locale?/login" element={loginElement} />
        <Route
          path={"/:locale?/x/registration"}
          element={
            authenticated && !!endpointPermissions ? (
              <Navigate to={getLocalizedLink("/")} />
            ) : (
              <RegistrationPage />
            )
          }
        />

        {routers.map((route, index) => {
          return (
            <Route
              key={index}
              path={`/:locale?/x${route.path}`}
              element={
                <SuspenseWrapper
                  pageComponentPath={route.pathComponentPage}
                  permission={route.permission}
                  cookiesLang={cookies.i18next}
                  checkFullAccess={route?.checkFullAccess}
                  dispatch={dispatch}
                />
              }
            />
          );
        })}
      </Route>
      <Route
        path="*"
        element={
          !authenticated ? (
            <Navigate to={getLocalizedLink("/login")} replace={true} />
          ) : (
            <Navigate
              to={getLocalizedLink(`${typeAccountRoutes.CRM}/page-not-found`)}
              replace={true}
            />
          )
        }
      />
    </Routes>
  );
};

export default RouterBlock;
