import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router-dom";
import { Trans, withTranslation } from "react-i18next";

import i18nHelper from "../../../i18nHelper";
import { StyledSpan, StyledTextRegular } from "../../styles/styledTypography";
import {
  StyledSidebarLink,
  StyledSidebarItem,
} from "./styledSidebar";
import { handleSidebarToggled } from "../../../store/theme/themeSlice";
import SidebarSubMenu from "./SidebarSubMenu";
import CustomLink from "../../elements/customLink/CustomLink";

const SidebarItem = ({
  to,
  icon,
  title,
  subLinks,
  t,
}) => {
  const dispatch = useDispatch();

  const { sidebarCollapsed } = useSelector((store) => store.theme);

  const language = i18nHelper.getCurrentLanguage();

  const match = useMatch(`${language}${to}`);

  const sidebarItemFactory = (subLinks) => {
    if (subLinks) {
      return (
        <SidebarSubMenu
          t={t}
          title={title}
          icon={icon}
          subLinks={subLinks}
          match={match}
          sidebarCollapsed={sidebarCollapsed}
        />
      );
    }

    return (
      <StyledSidebarLink
        as={CustomLink}
        to={to}
        className={match && "active"}
        onClick={() => dispatch(handleSidebarToggled())}
      >
        <StyledSidebarItem collapsed={sidebarCollapsed}>
          <StyledSpan fontSize="20" className={icon} />
          <StyledTextRegular>
            <Trans>{t(title)}</Trans>
          </StyledTextRegular>
        </StyledSidebarItem>
      </StyledSidebarLink>
    );
  };

  return sidebarItemFactory(subLinks);
};

export default withTranslation("translation", { keyPrefix: "sidebar" })(SidebarItem);
