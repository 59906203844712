import { t } from "i18next";

import { closableNotification } from "../../../components/elements/notification/ClosableNotification";
import { errorsParser } from "../../../utils/errorsParser";
import {
  changeMyExchangerAvailableServices,
  changeMyExchangerInputData,
  changeMyExchangerPairUnit,
  changeMyExchangerPairUnitActivity,
  changeMyExchangerPairUnitActivityForAll,
  changeMyExchangerPairUnitMulti,
  changeMyExchangerPairUnitMultiForAll,
  changePairUnitActivity,
  changePairUnitsMultiActivity,
  changePairUnitsMultiActivityForAll,
  fetchMyExchangerAvailableServices,
  fetchPairUnits,
  fetchUserPairUnits,
} from "./asyncPairUnitSlice";

const fetchPairUnitsReducer = (builder) => {
  builder.addCase(fetchPairUnits.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(fetchPairUnits.fulfilled, (state, action) => {
    state.pairUnits = action.payload.data;
    state.paginationInfo = { ...state.paginationInfo, ...action.payload.paginationInfo };
    state.loading = false;
  });
  builder.addCase(fetchPairUnits.rejected, (state, action) => {
    errorsParser(action.payload);
    state.loading = false;
  });
};

const changePairUnitActivityReducer = (builder) => {
  builder.addCase(changePairUnitActivity.pending, (state) => {
    state.changeActivityState.loading = true;
  });
  builder.addCase(changePairUnitActivity.fulfilled, (state, action) => {
    const newObjectPairUnit = action.payload.data;

    state.pairUnits = state.pairUnits.map((item) => {
      if (item.id === newObjectPairUnit.id) {
        return {
          ...item,
          basePairUnitId: item.id,
          isActive: newObjectPairUnit.isActive,
        };
      }

      return item;
    });

    closableNotification(t("alerts.activityChanged"), "success");
    state.errors = null;
    state.changeActivityState.loading = false;
  });
  builder.addCase(changePairUnitActivity.rejected, (state, action) => {
    errorsParser(action.payload);
    state.changeActivityState.loading = false;
  });
};

const changeMyExchangerPairUnitActivityReducer = (builder) => {
  builder.addCase(changeMyExchangerPairUnitActivity.pending, (state) => {
    state.changeActivityState.loading = true;
  });
  builder.addCase(changeMyExchangerPairUnitActivity.fulfilled, (state, action) => {
    const newArrayPair = action.payload.data?.data;

    state.pairUnits = state.pairUnits.map((item) => {
      const updatedPair = newArrayPair.find((newItem) => newItem.id === item.id);

      if (updatedPair) {
        return {
          ...item,
          exchangerPairUnitId: updatedPair.exchangerPairUnitId,
          isActive: updatedPair.isActive,
          fee: updatedPair.fee,
        };
      }

      return item;
    });

    closableNotification(t("alerts.paymentSystemActivityChanged"), "success");
    state.errors = null;
    state.changeActivityState.loading = false;
  });
  builder.addCase(changeMyExchangerPairUnitActivity.rejected, (state, action) => {
    errorsParser(action.payload);
    state.changeActivityState.loading = false;
  });
};

const changeMyExchangerPairUnitActivityForAllReducer = (builder) => {
  builder.addCase(changeMyExchangerPairUnitActivityForAll.pending, (state) => {
    state.changeActivityState.loading = true;
    state.refreshing = true;
  });
  builder.addCase(changeMyExchangerPairUnitActivityForAll.fulfilled, (state, action) => {
    const { data } = action.payload;

    closableNotification(data, "success");
    state.refreshing = true;
    state.errors = null;
    state.changeActivityState.loading = false;
  });
  builder.addCase(changeMyExchangerPairUnitActivityForAll.rejected, (state, action) => {
    errorsParser(action.payload);
    state.changeActivityState.loading = false;
    state.refreshing = false;
  });
};

const changeMyExchangerPairUnitMultiReducer = (builder) => {
  builder.addCase(changeMyExchangerPairUnitMulti.pending, (state) => {
    state.changeActivityState.loading = true;
  });
  builder.addCase(changeMyExchangerPairUnitMulti.fulfilled, (state, action) => {
    const newArrayPair = action.payload.data;

    state.pairUnits = state.pairUnits.map((item) => {
      const updatedPair = newArrayPair.find((newItem) => newItem.id === item.id);

      if (updatedPair) {
        return {
          ...item,
          priority: updatedPair.priority,
          baseCurrencyRateServiceName: updatedPair.baseCurrencyRateServiceName,
          //exchangerPairUnitId: updatedPair.exchangerPairUnitId,
          fee: updatedPair.fee,
        };
      }

      return item;
    });

    closableNotification(t("alerts.allPairUnitsUpdated"), "success");
    state.errors = null;
    state.changeActivityState.loading = false;
  });
  builder.addCase(changeMyExchangerPairUnitMulti.rejected, (state, action) => {
    errorsParser(action.payload);
    state.changeActivityState.loading = false;
  });
};

const changeMyExchangerPairUnitMultiForAllReducer = (builder) => {
  builder.addCase(changeMyExchangerPairUnitMultiForAll.pending, (state) => {
    state.changeActivityState.loading = true;
    state.refreshing = true;
  });
  builder.addCase(changeMyExchangerPairUnitMultiForAll.fulfilled, (state, action) => {
    const { data } = action.payload;

    closableNotification(data, "success");
    state.refreshing = true;
    state.errors = null;
    state.changeActivityState.loading = false;
  });
  builder.addCase(changeMyExchangerPairUnitMultiForAll.rejected, (state, action) => {
    errorsParser(action.payload);
    state.changeActivityState.loading = false;
    state.refreshing = false;
  });
};

const changePairUnitsMultiActivityReducer = (builder) => {
  builder.addCase(changePairUnitsMultiActivity.pending, (state) => {
    state.changeActivityState.loading = true;
  });
  builder.addCase(changePairUnitsMultiActivity.fulfilled, (state, action) => {
    const newArrayPair = action.payload.data;

    state.pairUnits = state.pairUnits.map((item) => {
      const updatedPair = newArrayPair.find((newItem) => newItem.id === item.id);

      if (updatedPair) {
        return {
          ...item,
          basePairUnitId: item.id,
          isActive: updatedPair.isActive,
        };
      }

      return item;
    });

    closableNotification(t("alerts.allPairUnitsUpdated"), "success");
    state.errors = null;
    state.changeActivityState.loading = false;
  });
  builder.addCase(changePairUnitsMultiActivity.rejected, (state, action) => {
    errorsParser(action.payload);
    state.changeActivityState.loading = false;
  });
};

const changePairUnitsMultiActivityForAllReducer = (builder) => {
  builder.addCase(changePairUnitsMultiActivityForAll.pending, (state) => {
    state.changeActivityState.loading = true;
    state.refreshing = true;
  });
  builder.addCase(changePairUnitsMultiActivityForAll.fulfilled, (state, action) => {
    const { data } = action.payload;

    closableNotification(data, "success");

    state.refreshing = true;
    state.errors = null;
    state.changeActivityState.loading = false;
  });
  builder.addCase(changePairUnitsMultiActivityForAll.rejected, (state, action) => {
    errorsParser(action.payload);
    state.changeActivityState.loading = false;
    state.refreshing = false;
  });
};

const changeMyExchangerInputDataReducer = (builder) => {
  builder.addCase(changeMyExchangerInputData.pending, (state) => {
    state.changeDataState.loading = true;
  });
  builder.addCase(changeMyExchangerInputData.fulfilled, (state) => {
    state.changeDataState.loading = false;
    closableNotification(t("alerts.valueUpdated"), "success");
  });
  builder.addCase(changeMyExchangerInputData.rejected, (state, action) => {
    state.changeDataState.loading = false;
    errorsParser(action.payload);
  });
};

const fetchUserPairUnitsReducer = (builder) => {
  builder.addCase(fetchUserPairUnits.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(fetchUserPairUnits.fulfilled, (state, action) => {
    state.pairUnits = action.payload.data;
    state.paginationInfo = { ...state.paginationInfo, ...action.payload.paginationInfo };
    state.loading = false;
  });
  builder.addCase(fetchUserPairUnits.rejected, (state, action) => {
    errorsParser(action.payload);
    state.loading = false;
  });
};

const fetchMyExchangerAvailableServicesReducer = (builder) => {
  builder.addCase(fetchMyExchangerAvailableServices.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(fetchMyExchangerAvailableServices.fulfilled, (state, action) => {
    state.availableServices = action.payload.data;
  });
  builder.addCase(fetchMyExchangerAvailableServices.rejected, (state, action) => {
    errorsParser(action.payload);
    state.loading = false;
  });
};

const changeMyExchangerPairUnitReducer = (builder) => {
  builder.addCase(changeMyExchangerPairUnit.pending, (state) => {
    state.changeDataState.loading = true;
  });
  builder.addCase(changeMyExchangerPairUnit.fulfilled, (state) => {
    state.changeDataState.loading = false;
    closableNotification(t("alerts.valueUpdated"), "success");
  });
  builder.addCase(changeMyExchangerPairUnit.rejected, (state, action) => {
    state.changeDataState.loading = false;
    errorsParser(action.payload);
  });
};

const changeMyExchangerAvailableServicesReducer = (builder) => {
  builder.addCase(changeMyExchangerAvailableServices.pending, (state) => {
    state.changeAvailableServices.loading = true;
  });
  builder.addCase(changeMyExchangerAvailableServices.fulfilled, (state) => {
    closableNotification(t("alerts.rateServiceChanged"), "success");
    state.errors = null;
    state.changeAvailableServices.loading = false;
  });
  builder.addCase(changeMyExchangerAvailableServices.rejected, (state, action) => {
    errorsParser(action.payload);
    state.changeAvailableServices.loading = false;
  });
};

export {
  changeMyExchangerAvailableServicesReducer,
  changeMyExchangerInputDataReducer,
  changeMyExchangerPairUnitActivityForAllReducer,
  changeMyExchangerPairUnitActivityReducer,
  changeMyExchangerPairUnitMultiForAllReducer,
  changeMyExchangerPairUnitMultiReducer,
  changeMyExchangerPairUnitReducer,
  changePairUnitActivityReducer,
  changePairUnitsMultiActivityForAllReducer,
  changePairUnitsMultiActivityReducer,
  fetchMyExchangerAvailableServicesReducer,
  fetchPairUnitsReducer,
  fetchUserPairUnitsReducer,
};
