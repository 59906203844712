import { t } from "i18next";

import { closableNotification } from "../../components/elements/notification/ClosableNotification";
import { errorsParser } from "../../utils/errorsParser";
import {
  fetchExchangerUserBillingAddress,
  updateUserBillingAddress,
  validateUserBillingAddress,
} from "./asyncBillingAddress";

const fetchExchangerUserBillingAddressReducer = (builder) => {
  builder.addCase(fetchExchangerUserBillingAddress.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(fetchExchangerUserBillingAddress.fulfilled, (state, action) => {
    state.billingAddress = action.payload.data;
    state.loading = false;
  });
  builder.addCase(fetchExchangerUserBillingAddress.rejected, (state, action) => {
    errorsParser(action.payload);
    state.loading = false;
  });
};

const updateUserBillingAddressReducer = (builder) => {
  builder.addCase(updateUserBillingAddress.pending, (state) => {
    state.updateBillingAddressLoading = true;
  });
  builder.addCase(updateUserBillingAddress.fulfilled, (state, action) => {
    state.billingAddress = action.payload.data;
    state.updateBillingAddressLoading = false;

    closableNotification(t("alerts.billingAddressUpdated"), "success");
  });
  builder.addCase(updateUserBillingAddress.rejected, (state, action) => {
    errorsParser(action.payload);
    state.updateBillingAddressLoading = false;
  });
};

const validateUserBillingAddressReducer = (builder) => {
  builder.addCase(validateUserBillingAddress.rejected, (state, action) => {
    state.errors = action.payload;
  });
};

export {
  fetchExchangerUserBillingAddressReducer,
  updateUserBillingAddressReducer,
  validateUserBillingAddressReducer,
};
