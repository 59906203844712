import Dropdown from "rc-dropdown";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { defaultRoute, exchangerRoles, mainRoles } from "../../utils/consts";
import eventBus from "../../utils/eventBus";
import CustomLink from "../elements/customLink/CustomLink";
import {
  StyledUserDescription,
  StyledUserDropdown,
  StyledUserDropdownButton,
  StyledUserIcon,
  StyledUserInfo,
} from "../layout/header/styledHeader";

const UserInfo = ({ email, isMobile, t }) => {
  const { roles } = useSelector((store) => store.user.user) || {};

  const isExchanger = process.env.REACT_APP_BASE_URL !== window.location.origin;

  const userSettingsPermission = roles?.some((role) =>
    [exchangerRoles.ADMIN, mainRoles.MERCHANT].includes(role)
  );

  const [isOpen, setIsOpen] = useState(false);

  const handleDropdownVisibilityChange = (visible) => {
    setIsOpen(visible);
  };

  const dropdownMenu = () => {
    return (
      <StyledUserDropdown>
        <ul>
          {((!isExchanger && userSettingsPermission) ||
            (isExchanger && !userSettingsPermission)) && (
            <li>
              <CustomLink to="/crm/settings">{t("settings")}</CustomLink>
            </li>
          )}
          <li>
            <CustomLink onClick={() => eventBus.dispatch("logout")}>{t("logout")}</CustomLink>
          </li>
        </ul>
      </StyledUserDropdown>
    );
  };

  return isMobile ? (
    <StyledUserInfo>
      <StyledUserIcon>
        <span>{email?.charAt(0)}</span>
      </StyledUserIcon>
      <StyledUserDescription>
        <p>
          {email}
        </p>
        {isMobile &&
          <button onClick={() => eventBus.dispatch("logout")}>
            <span className="icon-ex-menu-exit" />
          </button>
        }
      </StyledUserDescription>
    </StyledUserInfo>
  ) : (
    <Dropdown
      overlay={dropdownMenu}
      trigger={["click"]}
      placement="bottomRight"
      onVisibleChange={handleDropdownVisibilityChange}
    >
      <StyledUserInfo>
        <StyledUserIcon>
          <span>{email?.charAt(0)}</span>
        </StyledUserIcon>
        <StyledUserDescription>
          <p>
            {email}
          </p>
        </StyledUserDescription>
        <StyledUserDropdownButton>
          <span className={`icon-chevron-${isOpen ? "up" : "down"}`} />
        </StyledUserDropdownButton>
      </StyledUserInfo>
    </Dropdown>
  );
};

export default withTranslation("translation", { keyPrefix: "sidebar" })(UserInfo);
