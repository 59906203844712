export const permissionAdminEndpoints = {
  PUT_EXCHANGER_CHANGE_AVATAR: {
    name: "put_exchanger_change_avatar",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  PUT_EXCHANGER_REMOVE_AVATAR: {
    name: "put_exchanger_remove_avatar",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_BALANCES_GET_COLLECTION: {
    name: "api_exchanger_balances_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_BALANCES_GET_ITEM: {
    name: "api_exchanger_balances_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_BALANCES: {
    name: "get_exchanger_balances",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_INVOICES_GET_COLLECTION: {
    name: "api_exchanger_invoices_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_INVOICES_GET_ITEM: {
    name: "api_exchanger_invoices_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_ORDERS_GET_COLLECTION: {
    name: "api_exchanger_orders_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_ORDERS_GET_ITEM: {
    name: "api_exchanger_orders_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  EXCHANGER_UPDATE_ORDER: {
    name: "exchanger_update_order",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  GET_EXCHANGER_PAIR_UNITS: {
    name: "get_exchanger_pair_units",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_PAIR_UNITS_GET_COLLECTION: {
    name: "api_exchanger_pair_units_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_PAIR_UNITS_GET_ITEM: {
    name: "api_exchanger_pair_units_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_PAIR_UNITS_PUT_ITEM: {
    name: "api_exchanger_pair_units_put_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  FILL_EXCHANGER_PAIR_UNITS: {
    name: "fill_exchanger_pair_units",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_PAIR_UNIT_TABS_GET_COLLECTION: {
    name: "api_exchanger_pair_unit_tabs_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_PAIR_UNIT_TABS_GET_ITEM: {
    name: "api_exchanger_pair_unit_tabs_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_PAIRS_GET_COLLECTION: {
    name: "api_exchanger_pairs_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_PAIRS_GET_ITEM: {
    name: "api_exchanger_pairs_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_PAIRS_CHANGE_PERCENT_ITEM: {
    name: "api_exchanger_pairs_change_percent_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_PAIRS_PUT_ITEM: {
    name: "api_exchanger_pairs_put_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_USER_INFOS_GET_COLLECTION: {
    name: "api_exchanger_user_infos_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_USER_INFOS_GET_ITEM: {
    name: "api_exchanger_user_infos_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_USERS_GET_COLLECTION: {
    name: "api_exchanger_users_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_USERS_GET_ITEM: {
    name: "api_exchanger_users_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_USERS_PATCH_ITEM: {
    name: "api_exchanger_users_patch_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_USERS_WIDGET_STATE_ITEM: {
    name: "api_exchanger_users_change_widget_state_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_WIDGET_PAIRS_GET_COLLECTION: {
    name: "api_exchanger_widget_pairs_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_WIDGET_PAIRS_GET_ITEM: {
    name: "api_exchanger_widget_pairs_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_WIDGETS_PATCH_ITEM: {
    name: "api_exchanger_widgets_patch_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_WIDGET_PAIRS_PUT_ITEM: {
    name: "api_exchanger_widget_pairs_put_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_WIDGETS_GET_COLLECTION: {
    name: "api_exchanger_widgets_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_WIDGETS_GET_ITEM: {
    name: "api_exchanger_widgets_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_WIDGET_SETTINGS_GET_COLLECTION: {
    name: "api_exchanger_widget_settings_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  POST_EXCHANGER_WIDGET_SETTINGS: {
    name: "post_exchanger_widget_settings",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_WIDGET_SETTINGS_GET_ITEM: {
    name: "api_exchanger_widget_settings_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_REFERRAL_USER_INFO_PUT_ITEM: {
    name: "api_exchanger_referral_user_infos_put_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_ENDPOINTS_GET_COLLECTION: {
    name: "api_exchanger_endpoints_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_ENDPOINTS_GET_ITEM: {
    name: "api_exchanger_endpoints_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_ENDPOINT_PERMISSIONS_GET_COLLECTION: {
    name: "api_exchanger_endpoint_permissions_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_ENDPOINT_PERMISSIONS_POST_COLLECTION: {
    name: "api_exchanger_endpoint_permissions_post_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_ENDPOINT_PERMISSIONS_GET_ITEM: {
    name: "api_exchanger_endpoint_permissions_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_ENDPOINT_PERMISSIONS_DELETE_ITEM: {
    name: "api_exchanger_endpoint_permissions_delete_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  GET_EXCHANGER_ENDPOINT_PERMISSION_BY_USER_ID: {
    name: "get_exchanger_endpoint_permission_by_user_id",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  PUT_EXCHANGER_ENDPOINT_PERMISSION_EDIT_ACCESS: {
    name: "put_exchanger_endpoint_permission_edit_access",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  POST_EXCHANGER_ENDPOINT_PERMISSION_BY_GROUP: {
    name: "post_exchanger_endpoint_permission_by_group",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  GET_EXCHANGER_ENDPOINTS_LIST: {
    name: "get_exchanger_endpoints_list",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_GROUP_PERMISSIONS_GET_COLLECTION: {
    name: "api_exchanger_group_permissions_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_GROUP_PERMISSIONS_GET_ITEM: {
    name: "api_exchanger_group_permissions_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  EXCHANGER_UPDATE_DATABASE_SCHEMA: {
    name: "exchanger_update_database_schema",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_PAIR_UNITS_CHANGE_RATE_UPDATE_SERVICE_ITEM: {
    name: "api_exchanger_pair_units_change_rate_update_service_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  GET_EXCHANGER_RATES: {
    name: "get_exchanger_rates",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_FEES_GET_COLLECTION: {
    name: "api_exchanger_fees_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_FEES_GET_ITEM: {
    name: "api_exchanger_fees_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_FEES_PATCH_ITEM: {
    name: "api_exchanger_fees_patch_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  FILL_EXCHANGER_PERMISSIONS: {
    name: "fill_exchanger_permissions",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  FILL_EXCHANGER_ENDPOINT_PERMISSIONS_FOR_NEW_USER: {
    name: "fill_exchanger_endpoint_permissions_for_new_user",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  EXCHANGER_PAYMENT_SYSTEM: {
    name: "exchanger_payment_system",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  EXCHANGER_CURRENCY: {
    name: "exchanger_currency",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  EXCHANGER_SERVICE: {
    name: "exchanger_service",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  GET_EXCHANGER_AUTHENTICATOR_SECRET: {
    name: "get_exchanger_authenticator_secret",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  GET_EXCHANGER_AUTHENTICATOR_SECRET_IS_ACTIVE: {
    name: "get_exchanger_authenticator_secret_is_active",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  PUT_EXCHANGER_AUTHENTICATOR_SECRET_REMOVE: {
    name: "put_exchanger_authenticator_secret_remove",
    hasFullAccess: true,
    isTwoFaRequired: true,
  },
  POST_EXCHANGER_AUTHENTICATOR_SECRET_ADD: {
    name: "post_exchanger_authenticator_secret_add",
    hasFullAccess: true,
    isTwoFaRequired: true,
  },
  MAIN_EXCHANGER_BALANCE: {
    name: "main_exchanger_balance",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  EXCHANGER_REFRESH_TOKEN: {
    name: "exchanger_refresh_token",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  GET_EXCHANGER_RATE_SERVICES: {
    name: "get_exchanger_rate_services",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  CHANGE_EXCHANGER_PAIR_UNITS_RATE_SERVICE: {
    name: "change_exchanger_pair_units_rate_service",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_USERS_BAN_USER_ITEM: {
    name: "api_exchanger_users_ban_user_item",
    hasFullAccess: true,
    isTwoFaRequired: true,
  },
  API_EXCHANGER_REFERRAL_USER_INFOS_GET_COLLECTION: {
    name: "api_exchanger_referral_user_infos_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_REFERRAL_USER_INFOS_GET_ITEM: {
    name: "api_exchanger_referral_user_infos_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_REFERRAL_USER_INFOS_PUT_ITEM: {
    name: "api_exchanger_referral_user_infos_put_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  GET_EXCHANGER_USER_REFERRAL_INFO: {
    name: "get_exchanger_user_referral_info",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  CHANGE_EXCHANGER_RATES_MARKUP: {
    name: "change_exchanger_rates_markup",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  RESET_EXCHANGER_USER_VERIFICATION: {
    name: "reset_exchanger_user_verification",
    hasFullAccess: true,
    isTwoFaRequired: true,
  },
  GET_EXCHANGER_USER_VERIFIED_VERIFICATION_SCHEMAS: {
    name: "get_exchanger_user_verified_verification_schemas",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  GET_EXCHANGER_USER_VERIFICATIONS: {
    name: "get_exchanger_user_verifications",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  POST_EXCHANGER_USER_VERIFICATION: {
    name: "post_exchanger_user_verification",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  GET_EXCHANGER_REFERRAL_USER_LIST_BY_ID: {
    name: "get_exchanger_referral_user_list_by_id",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  GET_EXCHANGER_LOGS: {
    name: "get_exchanger_logs",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  POST_CALCULATOR_SETTINGS: {
    name: "post_calculator_settings",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  DELETE_CALCULATOR_SETTINGS: {
    name: "delete_calculator_settings",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  GET_CALCULATOR_SETTINGS: {
    name: "get_calculator_settings",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  GET_EXCHANGER_ORDERS_REPORT: {
    name: "get_exchanger_orders_report",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_WITHDRAWAL_REQUISITIONS_PATCH_ITEM: {
    name: "api_exchanger_withdrawal_requisitions_patch_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_WITHDRAWAL_REQUISITION_GET_COLLECTION: {
    name: "api_exchanger_withdrawal_requisitions_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  GET_EXCHANGER_WIDGET_REPORTS: {
    name: "get_exchanger_widget_reports",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  PUT_EXCHANGER_USER_INFO: {
    name: "put_exchanger_user_info",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_TRAFFIC_LINKS_GET_COLLECTION: {
    name: "api_exchanger_traffic_links_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_TRAFFIC_LINKS_POST_COLLECTION: {
    name: "api_exchanger_traffic_links_post_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_TRAFFIC_LINKS_GET_ITEM: {
    name: "api_exchanger_traffic_links_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  POST_EXCHANGER_USER_ASSIGN_REFERRER: {
    name: "post_exchanger_user_assign_referrer",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_BLACK_LIST_WALLETS_GET_COLLECTION: {
    name: "api_exchanger_black_list_wallets_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_BLACK_LIST_WALLETS_GET_ITEM: {
    name: "api_exchanger_black_list_wallets_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_BLACK_LIST_WALLETS_POST_COLLECTION: {
    name: "api_exchanger_black_list_wallets_post_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_BLACK_LIST_WALLETS_DELETE_ITEM: {
    name: "api_exchanger_black_list_wallets_delete_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  CHANGE_PAIR_UNIT_FOR_PAIRS: {
    name: "change_pair_unit_for_pairs",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  CHANGE_PAIR_UNIT_FOR_WIDGET_PAIRS: {
    name: "change_pair_unit_for_widget_pairs",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  GET_EXCHANGER_WIDGET_ORDERS: {
    name: "get_exchanger_widget_orders",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  GET_EXCHANGER_MAILING_LANGUAGE: {
    name: "get_exchanger_mailing_language",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  UPDATE_EXCHANGER_MAILING_LANGUAGE: {
    name: "update_exchanger_mailing_language",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
};
