import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../utils/setupAxiosInterceptors";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";

const fetchExchangerUserBillingAddress = createAsyncThunk(
  "billingAddress/fetchExchangerUserBillingAddress",
  async (userId, thunkAPI) => {
    try {
      const response = await axiosInstance(
        `/api/exchanger-users-billing-address/${userId}`,
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const updateUserBillingAddress = createAsyncThunk(
  "billingAddress/updateUserBillingAddress",
  async ({ userId, data }, thunkAPI) => {
    try {
      const response = await axiosInstance.put(
        `/api/exchanger-users/update-user-billing-address/${userId}`,
        data,
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const validateUserBillingAddress = createAsyncThunk(
  "billingAddress/validateUserBillingAddress",
  async (data, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/api/exchanger-users/validate-billing-address",
        data,
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

export { fetchExchangerUserBillingAddress, updateUserBillingAddress, validateUserBillingAddress };
