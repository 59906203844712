import { createSlice } from "@reduxjs/toolkit";

import { errorsParser } from "../../utils/errorsParser";
import {
  createPaymentInvoiceByOrder,
  fetchOrderDetails,
  updateOrderStatus,
} from "./asyncOrderDetailsSlice";

const initialState = {
  order: {},
  error: null,
  loading: true,
  createPaymentInvoiceState: {
    loading: false,
    errors: null,
  },
};

const orderDetailsSlice = createSlice({
  name: "orderDetailsSlice",
  initialState,
  reducers: {
    clearOrderDetailsStore: (state) => {
      state.order = {};
      state.loading = true;
    },
    updateOrderStore: (state, action) => {
      const pusherObject = JSON.parse(action.payload);

      state.order = {
        ...state.order,
        ...pusherObject,
        invoices: Array.isArray(pusherObject.invoices)
          ? pusherObject.invoices
          : state.order.invoices || [],
        orderAttributes: Array.isArray(pusherObject.orderAttributes)
          ? pusherObject.orderAttributes
          : state.order.orderAttributes || [],
        orderFlowData: Array.isArray(pusherObject.orderFlowData)
          ? pusherObject.orderFlowData
          : state.order.orderFlowData || [],
        orderProfits: Array.isArray(pusherObject.orderProfits)
          ? pusherObject.orderProfits
          : state.order.orderProfits || [],
      };
    },
    setOrderStatus: (state, action) => {
      state.order.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrderDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchOrderDetails.fulfilled, (state, action) => {
      state.order = action.payload.data;
      state.loading = false;
    });
    builder.addCase(fetchOrderDetails.rejected, (state, action) => {
      errorsParser(action.payload, true);
      state.loading = false;
    });
    builder.addCase(createPaymentInvoiceByOrder.pending, (state) => {
      state.createPaymentInvoiceState.loading = true;
    });
    builder.addCase(createPaymentInvoiceByOrder.fulfilled, (state, action) => {
      state.order = {
        ...state.order,
        status: action.payload.data.status,
        invoices: action.payload.data.invoices,
        updatedAt: action.payload.data.updatedAt,
      };
      state.createPaymentInvoiceState.loading = false;
    });
    builder.addCase(createPaymentInvoiceByOrder.rejected, (state, action) => {
      errorsParser(action.payload);
      state.createPaymentInvoiceState.loading = false;
    });

    builder.addCase(updateOrderStatus.pending, (state) => {
      state.createPaymentInvoiceState.loading = true;
    });
    builder.addCase(updateOrderStatus.fulfilled, (state, action) => {
      state.order = {
        ...state.order,
        status: action.payload.data.status,
      };
      state.createPaymentInvoiceState.loading = false;
    });
    builder.addCase(updateOrderStatus.rejected, (state, action) => {
      errorsParser(action.payload);
      state.createPaymentInvoiceState.loading = false;
    });
  },
});

export const { clearOrderDetailsStore, updateOrderStore, setOrderStatus } =
  orderDetailsSlice.actions;

export default orderDetailsSlice.reducer;
