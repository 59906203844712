import styled from "styled-components";

import { StyledMobileNavigation } from "../../elements/navigation/styledNavigation";
import { StyledContainer } from "../../styles/styledContainer";

export const StyledHeaderWrapper = styled.header`
  padding: 12px 20px;
  width: 100%;
  height: 65px;
  color: ${({ theme }) => theme.neutral.white};
  background-color: ${({ theme }) => theme.headerBackgroundColor};
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  // ${({ isAccountPathname, theme }) => isAccountPathname && `
  //    &::after {
  //     content: "";
  //     width: calc(100vw - 257px - 40px - 5px - 15px);
  //     height: 5px;
  //     position: absolute;
  //     top: 100%;
  //     right: 0;
  //     background: ${theme.brandColor};
  //     background: linear-gradient(90deg, ${theme.brandColor} 50%, rgba(45,48,56,1) 100%);
  //   }
  // `};

  @media screen and (max-width: 992px) {
    height: 58px;
    padding: 10px 14px;

    // ${({ isAccountPathname, theme }) => isAccountPathname && `
    //  &::after {
    //   width: 100vw;
    //   height: 3px;
    //   background: linear-gradient(90deg, ${theme.brandColor} 0%, rgba(45,48,56,1) 100%);
    // }
    
     &::after {
      content: "";
      width: 100vw;
      height: 3px;
      position: absolute;
      top: 100%;
      right: 0;
      background: ${theme.brandColor};
      background: linear-gradient(90deg, ${theme.brandColor} 50%, rgba(45,48,56,1) 100%);
    }
  `};
  }
`;

export const StyledHeaderContainer = styled(StyledContainer)`
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border: none;
  position: static;
  overflow-x: visible;

  .header-left,
  .header-right {
    max-width: 450px;
    width: 100%;
    display: inline-flex;
    align-items: center;
  }

  .header-right {
    justify-content: flex-end;

    &__account {
      margin-right: 40px;
      transition: all 0.3s ease;
      display: inline-flex;
      align-items: center;
      gap: 6px;

      .icon-ex-input-usename {
        color: ${({ theme }) => theme.neutral.fourth};
        font-size: 24px;
      }

      &:hover {
        color: ${({ theme }) => theme.neutral.five};

        .icon-ex-input-usename {
          color: ${({ theme }) => theme.primary.first};
        }
      }

      &-mobile {
        display: none;
      }
    }

    &__balance {
      margin-right: 40px;
      display: flex;
      gap: 4px;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
      }

      p {
        font-weight: 500;
      }

      &:hover {
        color: ${({ theme }) => theme.neutral.five};
      }
    }
  }

  @media screen and (max-width: 992px) {
    .header-right {
      &__account {
        margin-right: 16px;
      }

      &__balance {
        display: none;
      }
    }
  }
  
  @media screen and (max-width: 768px) {
    //display: grid;
    //grid-template-columns: 1fr repeat(2, max-content);
    //.header-left,
    //.header-right {
    //  &__account {
    //    display: none;
    //  }
    //}
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    background-color: transparent;
    border: none;
    position: static;
    overflow-x: visible;

    .header-left {
      //order: 2;
      //width: 100%;
      //display: inline-flex;
      //align-items: center;
    }

    .header-right {
      //order: 3;

      &__account {
        &-title {
          display: none;
        }
        
        &-pc {
          display: none;
        }
        
        &-mobile {
          display: flex;
          margin-left: 8px;
          margin-right: 0;
        }
      }
    }
  }
`;

export const StyledMobileSideBarIcon = styled(StyledMobileNavigation)`
  @media screen and (max-width: 992px) {
    margin-left: 8px;
    display: block;
  }
`;

export const StyledUserInfo = styled.div`
  margin-right: 40px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  @media screen and (max-width: 992px) {
    margin: 0 20px 20px;
    padding: 16px;
    border-radius: 16px;
    background-color: ${({ theme }) => theme.sidebar.currentItemBackgroundColor};
    display: grid;
    grid-template-columns: 34px 1fr;
    cursor: inherit;
  }
`;

export const StyledUserIcon = styled.div`
  width: 34px;
  height: 34px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.primary.first};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 992px) {
    font-size: 16px;
  }
`;

export const StyledUserDescription = styled.div`
  p {
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.neutral.five};
  }

  @media screen and (max-width: 992px) {
    &:has(*) {
      width: 100%;
      display: flex;
      justify-content: space-between;

      p {
        max-width: 175px;
      }

      button {
        color: ${({ theme }) => theme.sidebar.iconColor};
        font-size: 16px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: none;
        outline: none;
      }
    }
  }

  @media screen and (max-width: 374px) {
    &:has(*) {
      p {
        max-width: 120px;
      }
    }
  }
`;

export const StyledUserDropdownButton = styled.div`
  height: 20px;
  width: 20px;
  color: ${({ theme }) => theme.neutral.fourth};
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledUserDropdown = styled.div`
  width: 100%;
  padding: 8px;
  background-color: ${({ theme }) => theme.neutral.white};
  border: 1px solid ${({ theme }) => theme.neutral.five};
  border-radius: 12px;

  ul {
    li {
      a,
      button {
        width: 100%;
        padding: 10px 15px;
        color: ${({ theme }) => theme.neutral.first};
        font-size: 14px;
        font-family: Inter, sans-serif;
        display: flex;

        &:hover {
          color: ${({ theme }) => theme.primary.first};
        }
      }
    }
  }
`;
