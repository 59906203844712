const routes = [
  // {
  //   path: `/`,
  //   pathComponentPage: "/home/HomePage"
  // },
  // {
  //   path: `/page-not-found`,
  //   pathComponentPage: "/pageNotFound/PageNotFound",
  // },
  // {
  //   path: "/login",
  //   pathComponentPage: "/login/LoginPage",
  // },
  // {
  //   path: "/registration",
  //   pathComponentPage: "/registration/RegistrationPage",
  // },
  {
    path: "/change-mailing-settings/:token?",
    pathComponentPage: "/changeMailingSettings/ChangeMailingSettingsPage",
  },
  {
    path: "/email-confirm/:token?",
    pathComponentPage: "/emailConfirm/EmailConfirmPage",
  },
  {
    path: "/forgot-password",
    pathComponentPage: "/forgotPassword/ForgotPasswordPage",
  },
  {
    path: "/change-password/:token?",
    pathComponentPage: "/changePassword/ChangePasswordPage",
  },
  {
    path: "/email-verification/:id",
    pathComponentPage: "/emailVerification/EmailVerificationPage",
  },
  {
    path: "/ex/:metaUrl",
    pathComponentPage: "/widget/WidgetPage",
  },
];

export default routes;
