import styled from "styled-components";

export const StyledCalculatorLoader = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;

  ${({ widgetLogo }) => !widgetLogo && `
    padding-top: 142px;
  `};

  #extopWidget {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    //background-color: #F2F2F2;
    border-radius: 24px;
    position: relative;
    overflow: clip;
    scroll-margin-top: 0;
    
    ${({ widgetLayout }) => widgetLayout === "horizontal" ? `
      max-width: 900px;
      min-height: 500px;
    ` : `
      max-width: 480px;
      min-height: 640px;
    `};

    @media screen and (max-width: 768px) {
      max-width: 480px;
      min-height: 640px;
    }
  }

  .extop-widget-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
`;

export const StyledLoadingSpinner = styled.div`
  width: 32px;
  height: 32px;
  border: 1px solid rgba(149, 149, 149, 0.50);
  border-bottom-color: #333333;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;