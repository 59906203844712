import styled from "styled-components";

import { StyledSpan, StyledTextRegular } from "../../styles/styledTypography";

export const StyledSidebar = styled.div`
  height: calc(100% - 70px);
  padding: 32px 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2px;
  transition: background-color 0.3s ease-in-out;

  .sidebar-collapsed {
    cursor: initial;

    &:hover {
      .sidebar-collapsed__btn {
        color: ${({ theme }) => theme.sidebar.iconColor};
      }
    }

    &__btn:hover {
      color: #FFF !important;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 992px) {
    height: 100%;
    padding: 16px 0;
    background-color: ${({ theme }) => theme.sidebar.backgroundColor};

    .sidebar-collapsed {
      display: none;
    }

    .balance {
      margin: 0 20px 20px;
      color: #FFFFFF;
      display: flex;
      gap: 6px;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
      }

      p {
        font-size: 16px;
        font-weight: 500;
      }

      &::before {
        display: none;
      }
    }
  }
`;

export const StyledSidebarItem = styled.div`
  width: 100%;
  margin-right: 20px;
  padding: 17px 12px 17px 20px;
  display: flex;
  align-items: center;
  gap: 12px;
  border: none;
  border-radius: 0 17px 17px 0;
  outline: none;

  ${StyledTextRegular} {
    color: ${({ theme }) => theme.sidebar.textColor};
    white-space: nowrap;
  }

  ${({ collapsed }) => collapsed && `
    margin-right: 0;
    
    ${StyledTextRegular} {
      display: none;
    }
  `}

  ${StyledSpan} {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.sidebar.iconColor};
  }

  @media screen and (max-width: 992px) {
    padding: 14px 12px 14px 20px;
  }

  @media screen and (max-width: 768px) {
    ${StyledTextRegular} {
      display: block;
    }
  }
`;

export const StyledSidebarSiteLinks = styled.div`
  .active {
    ${StyledSpan},
    ${StyledTextRegular} {
      color: ${({ theme }) => theme.sidebar.currentItemTextColor};
    }
  }

  .active {
    position: relative;

    &::before {
      content: "";
      width: 5px;
      height: 100%;
      background-color: ${({ theme }) => theme.brandColor};
      position: absolute;
      top: 0;
      left: 0;
    }

    ${StyledSidebarItem} {
      background-color: ${({ theme }) => theme.sidebar.currentItemBackgroundColor};
    }

    @media screen and (max-width: 992px) {
      &::before {
        width: 3px;
      }
    }
  }
`;

export const StyledSidebarLink = styled.div`
  width: 100%;
  display: inline-flex;
  cursor: pointer;
  position: relative;
  text-overflow: ellipsis;

  &:hover {
    ${StyledSpan}, ${StyledTextRegular} {
      color: ${({ theme }) => theme.sidebar.currentItemTextColor};
    }
  }
`;

export const StyledSidebarAccount = styled.div`
  @media screen and (min-width: 992px) {
    display: none;
  }
`;

export const StyledSidebarSubLinkWrapper = styled.div`
  margin-top: 10px;
  padding-top: 20px;
  position: relative;

  &::before {
    content: "";
    height: 1px;
    width: calc(100% - 24px - 24px);
    background-color: ${({ theme }) => theme.sidebar.separateLineColor};
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .ps-menu-button {
    padding: 0 !important;
    position: relative !important;

    &:hover {
      background-color: transparent !important;
    }
  }

  .ps-submenu-expand-icon {
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translateY(calc(-50% - 1px));
    
    span {
      border-color: ${({ theme }) => theme.sidebar.textColor}; !important;
    }

    ${({collapsed, theme}) => collapsed && `
      right: 12px;
      
      span {
        background-color: ${theme.sidebar.textColor};
      }
    `}
  }

  .submenu {
    &__title {
      margin-right: 0;
      cursor: pointer;
      position: relative;

      ${StyledTextRegular} {
        font-weight: 700;
      }

      &:hover {
        ${StyledSpan}, ${StyledTextRegular} {
          color: ${({ theme }) => theme.sidebar.currentItemTextColor};
        }
      }
    }
  }
`;

export const StyledSidebarSubLinkList = styled.div`
  ${({ collapsed, theme }) => collapsed && `
    .ps-submenu-content {
      width: min-content;
      border-radius: 10px;
  
      ${StyledSidebarItem} {
        max-width: max-content;
        padding: 12px 18px;
        margin: 0;
        border-radius: none;
      }
    }
    
    .active {
      position: relative;
  
      &::before {
        display: none;
      }
  
      ${StyledSidebarItem} {
        color: ${theme.sidebar.itemBackgroundColorHover};
        background-color: transparent;
      }
    }
  `}

  .ps-submenu-content {
    background-color: ${({ collapsed, theme }) => (collapsed ? theme.sidebar.separateLineColor : "transparent")};
  }
`;